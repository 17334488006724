import React from "react";
import { Box, Grid, Typography, useMediaQuery,  Link } from "@mui/material";
import '../css/footer.css';
import companyLogo from '../resources/companyLogo.svg';
import linkedInLogo from '../resources/footer/linkedIn-logo.svg';
import gmailLogo from '../resources/footer/gmail-logo.svg';
import twitterLogo from '../resources/footer/twitter-logo.svg';
import facebookLogo from '../resources/footer/facebook-logo.svg';
import locationIcon from '../resources/footer/location-icon.svg';
import phoneIcon from '../resources/footer/phone-icon.svg';
import mailIcon from '../resources/footer/mail-icon-small.svg';
import { Height } from "@mui/icons-material";
import { socialLinks } from "../resources/const";

export const Footer = () => {

    const isSmallScreen = useMediaQuery('(max-width: 1000px)');
    const isLargeScreen = useMediaQuery('(min-width: 1000px)');
    const isSmallScreen1 = useMediaQuery('(max-width: 1000px)');
    const isLargeScreen1 = useMediaQuery('(min-width: 1000px)');
    const isLargeScreen2 = useMediaQuery('(min-width: 600px)')
    const logoArray = [
        {
            logo: linkedInLogo,
            url: socialLinks.linkedInLink
        },
        {
            logo: gmailLogo,
            url: socialLinks.gmailLink
        },
        {
            logo: twitterLogo,
            url: socialLinks.twitterLink
        },
        {
            logo: facebookLogo,
            url: socialLinks.facebookLink
        }
    ]

    return (
        <Box>
            <Box
                sx={{
                    background: "#1F1F1F",
                    py: 8,
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        pl: (()=>{
                            if(isLargeScreen2){
                                return 4
                            }else{
                                return 0
                            }
                        }),
                        pr: (()=>{
                            if(isLargeScreen2){
                                return 8
                            }else {
                                return 0
                            }
                        }),
                    }}
                >
                    <Box
                        component="img"
                        src={companyLogo}
                        sx={{
                            pl: (()=>{
                                if(isSmallScreen1){
                                    return 0
                                }else{
                                    return 4
                                }
                            })
                        }}
                    />
                    <Box>
                        <Typography
                            sx={{
                                color: "#6BB0C2",
                                fontFamily: "Poppins",
                                fontWeight: 400,
                            }}
                        >
                            Useful Links
                        </Typography>
                        <Box
                            sx={{
                                display: "flex",
                                mt: 2,
                            }}
                        >
                            <Box
                                sx={{
                                    pr: 2,
                                }}
                            >
                                <Link
                                    href="services"
                                    style={{
                                        textDecoration: "none"
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            color: "#FFFFFF",
                                            fontFamily: "Poppins",
                                            fontWeight: 400,
                                            mb: 2,
                                        }}
                                    >
                                        Services
                                    </Typography>
                                </Link>
                                <Link
                                    href="home"
                                    style={{
                                        textDecoration: "none"
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            color: "#FFFFFF",
                                            fontFamily: "Poppins",
                                            fontWeight: 400,
                                            mb: 2,
                                        }}
                                    >
                                        Testimonials
                                    </Typography>
                                </Link>
                                <Link
                                    href="careers"
                                    style={{
                                        textDecoration: "none"
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            color: "#FFFFFF",
                                            fontFamily: "Poppins",
                                            fontWeight: 400,
                                        }}
                                    >
                                        Career
                                    </Typography>
                                </Link>
                            </Box>
                            <Box
                                sx={{
                                    pl: 4
                                }}
                            >
                                <Link
                                    href="blogs"
                                    style={{
                                        textDecoration: "none"
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            color: "#FFFFFF",
                                            fontFamily: "Poppins",
                                            fontWeight: 400,
                                            mb: 2,
                                        }}
                                    >
                                        Blog
                                    </Typography>
                                </Link>
                                <Link
                                    href="contact"
                                    style={{
                                        textDecoration: "none"
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            color: "#FFFFFF",
                                            fontFamily: "Poppins",
                                            fontWeight: 400,
                                        }}
                                    >
                                        Contact Us
                                    </Typography>
                                </Link>
                            </Box>
                        </Box>
                    </Box>
                    {
                        isLargeScreen &&
                            <Box>
                                <Box
                                    sx={{
                                        display: "flex",
                                        justifyContent: "space-evenly"
                                    }}
                                >
                                    <Box>
                                        <Box
                                            sx={{
                                                display: "flex",
                                                mb: 1,
                                            }}
                                        >
                                            <Box
                                                component="img"
                                                src={locationIcon}
                                            />
                                            <Typography
                                                sx={{
                                                    fontFamily: "Poppins",
                                                    fontWeight: 400,
                                                    color: "#FFFFFF",
                                                    ml: 2
                                                }}
                                            >
                                                India
                                            </Typography>
                                        </Box>
                                        <Box
                                            sx={{
                                                display: "flex",
                                                mb: 1,
                                            }}
                                        >
                                            <Box
                                                component="img"
                                                src={phoneIcon}
                                            />
                                            <Typography
                                                sx={{
                                                    fontFamily: "Poppins",
                                                    fontWeight: 400,
                                                    color: "#FFFFFF",
                                                    ml: 2
                                                }}
                                            >
                                                +91 93390 54077
                                            </Typography>
                                        </Box>
                                        <Box
                                            sx={{
                                                display: "flex",
                                                mb: 1,
                                            }}
                                        >
                                            <Box
                                                component="img"
                                                src={mailIcon}
                                            />
                                            <Typography
                                                sx={{
                                                    fontFamily: "Poppins",
                                                    fontWeight: 400,
                                                    color: "#FFFFFF",
                                                    ml: 2
                                                }}
                                            >
                                                kunal@worthyadvisors.in
                                            </Typography>
                                        </Box>
                                        <Box>
                                            <Typography
                                                sx={{
                                                    fontFamily: "Poppins",
                                                    fontWeight: 400,
                                                    color: "#FFFFFF"
                                                }}
                                            >
                                                Purva Heights Bilekahalli,
                                            </Typography>
                                            <Typography
                                                sx={{
                                                    fontFamily: "Poppins",
                                                    fontWeight: 400,
                                                    color: "#FFFFFF"
                                                }}
                                            >
                                                Bannerghatta Road, 
                                            </Typography>
                                            <Typography
                                                sx={{
                                                    fontFamily: "Poppins",
                                                    fontWeight: 400,
                                                    color: "#FFFFFF"
                                                }}
                                            >
                                                Bangalore South, Bangalore, 
                                            </Typography>
                                            <Typography
                                                sx={{
                                                    fontFamily: "Poppins",
                                                    fontWeight: 400,
                                                    color: "#FFFFFF"
                                                }}
                                            >
                                                Karnataka-560076
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <Box>
                                        <Box
                                            sx={{
                                                ml: 4
                                            }}
                                        >
                                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3889.052391894753!2d77.59995737560006!3d12.904352687404852!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae151ec836aaab%3A0xf5154cbafa5d5790!2sPurva%20Heights!5e0!3m2!1sen!2sin!4v1692455380895!5m2!1sen!2sin" 
                                                style={{
                                                    width: "20vw",
                                                    height: "20vh",
                                                    borderRadius: "1rem",
                                                    border: "none"
                                                }}
                                            ></iframe>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                    }
                </Box>
            </Box>
            {
                    isSmallScreen &&
                    <Box
                        sx={{
                            background: "#1F1F1F",
                            py: 4,
                            pl: (()=>{
                                if(isLargeScreen2){
                                    return 4;
                                }else{
                                    return 0
                                }
                            })
                        }}
                    >
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "flex-start"
                            }}
                        >
                            <Box>
                                <Box
                                    sx={{
                                        display: "flex",
                                        mb: 1,
                                    }}
                                >
                                    <Box
                                        component="img"
                                        src={locationIcon}
                                    />
                                    <Typography
                                        sx={{
                                            fontFamily: "Poppins",
                                            fontWeight: 400,
                                            color: "#FFFFFF",
                                            ml: 2
                                        }}
                                    >
                                        India
                                    </Typography>
                                </Box>
                                <Box
                                    sx={{
                                        display: "flex",
                                        mb: 1,
                                    }}
                                >
                                    <Box
                                        component="img"
                                        src={phoneIcon}
                                    />
                                    <Typography
                                        sx={{
                                            fontFamily: "Poppins",
                                            fontWeight: 400,
                                            color: "#FFFFFF",
                                            ml: 2
                                        }}
                                    >
                                        +91 93390 54077
                                    </Typography>
                                </Box>
                                <Box
                                    sx={{
                                        display: "flex",
                                        mb: 1,
                                    }}
                                >
                                    <Box
                                        component="img"
                                        src={mailIcon}
                                    />
                                    <Typography
                                        sx={{
                                            fontFamily: "Poppins",
                                            fontWeight: 400,
                                            color: "#FFFFFF",
                                            ml: 2
                                        }}
                                    >
                                        kunal@worthyadvisors.in
                                    </Typography>
                                </Box>
                                <Box>
                                    <Typography
                                        sx={{
                                            fontFamily: "Poppins",
                                            fontWeight: 400,
                                            color: "#FFFFFF"
                                        }}
                                    >
                                        Purva Heights Bilekahalli,
                                    </Typography>
                                    <Typography
                                        sx={{
                                            fontFamily: "Poppins",
                                            fontWeight: 400,
                                            color: "#FFFFFF"
                                        }}
                                    >
                                        Bannerghatta Road, 
                                    </Typography>
                                    <Typography
                                        sx={{
                                            fontFamily: "Poppins",
                                            fontWeight: 400,
                                            color: "#FFFFFF"
                                        }}
                                    >
                                        Bangalore South, Bangalore, 
                                    </Typography>
                                    <Typography
                                        sx={{
                                            fontFamily: "Poppins",
                                            fontWeight: 400,
                                            color: "#FFFFFF"
                                        }}
                                    >
                                        Karnataka-560076
                                    </Typography>
                                </Box>
                            </Box>
                            <Box>
                                <Box
                                    sx={{
                                        ml: 4
                                    }}
                                >
                                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3889.052391894753!2d77.59995737560006!3d12.904352687404852!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae151ec836aaab%3A0xf5154cbafa5d5790!2sPurva%20Heights!5e0!3m2!1sen!2sin!4v1692455380895!5m2!1sen!2sin" 
                                        style={{
                                            width: "100%",
                                            height: "20vh",
                                            borderRadius: "1rem",
                                            border: "none"
                                        }}
                                    ></iframe>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                }
            {
                isLargeScreen &&
                    <Grid container spacing={2}
                    sx={{
                        background: "#282828",
                        pl: 4,
                        py: 2,
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center"
                    }}
                > 
                    <Grid item xs={4}>
                        <Typography
                            sx={{
                                fontFamily: "Open Sans",
                                fontWeight: 400,
                                color: "#838383",
                                fontSize: "0.75rem"
                            }}
                        >
                            Copyright 2023, All Right Reserved, WorthyAdvisors
                        </Typography>
                    </Grid>
                    <Grid 
                        item
                        xs={7}
                    >
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "flex-start",
                                
                            }}
                        >
                            {
                                logoArray.map((elem)=>{
                                    return (
                                        <Link
                                        sx={{
                                            textDecoration: "none"
                                        }}
                                        href={elem.url}
                                    >
                                        <Box
                                            component="img"
                                            src={elem.logo}
                                            sx={{
                                                px:1.5
                                            }}
                                        />
                                    </Link>
                                    )
                                })
                            }
                        </Box>
                    </Grid>
                </Grid>
            }
            {
                isSmallScreen &&
                    <Box
                        sx={{
                            background: "#282828"
                        }}
                    >
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                mb: 1,
                                pt: 1,
                            }}
                        >
                            {
                                logoArray.map((elem)=>{
                                    return (
                                        <Link
                                            sx={{
                                                textDecoration: "none"
                                            }}
                                            href={elem.url}
                                        >
                                            <Box
                                                component="img"
                                                src={elem.logo}
                                                sx={{
                                                    px:1.5
                                                }}
                                            />
                                        </Link>
                                    )
                                })
                            }
                        </Box>
                        <Typography
                            sx={{
                                fontFamily: "Open Sans",
                                fontWeight: 400,
                                color: "#838383",
                                fontSize: "0.75rem"
                            }}
                            align="center"
                        >
                            Copyright 2023, All Right Reserved, WorthyAdvisors
                        </Typography>
                    </Box>
            }
        </Box>
    )
}