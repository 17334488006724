export const homeConsts = {
    headings: {
        heading1: {
            head: "Transforming Treasury,  Accelerating  Growth",
            body: "Your Trusted Treasury Consulting and Outsourcing Partner. \n We Provide Expert Treasury Solutions for a Stronger Financial Foundation of your Company"
        },
        heading2: {
            head: "Who are we ?",
            body: "At Worthy Advisors, we are a team of dedicated, experienced professionals in the treasury domain. We democratize treasury services for mid-sized corporate and Growing Companies. Our team has managed portfolios totaling over Rs. 9000+ Crores, ensuring optimized income, cost reduction, and effective fund utilization. At Worthy Advisors, we're more than just a treasury consulting company. We're your partners in financial management, committed to propelling your business towards greater financial efficiency and success.",
        },
    },
    ourServicesSection: [
            {
                head: "Cash Flow Management",
                body: "Delivering data-driven cash flow solutions to businesses, optimizing liquidity management and  reducing financial risk",
            },
            {
                head: "Commercial Insurance",
                body: "Offering Comprehensive Solutions for Commercial Insurance, Risk Assessment,Risk Management, Liability Coverage, and Asset Protection",
            },
            {
                head: "Corporate Investments",
                body: "We help businesses to Manage Funds, Prepare Investment Horizon, Craft Investment Policy Statements, and Monitor Investments",
            },
            {
                head: "Corporate Banking",
                body: "Overall Banking Relationship Management, delivering Customized Banking, Budgeting, and CMS Services ",
            },
            {
                head: "Forex Management",
                body: "Managing Foreign Currency Exposure, Foreign Remittance, Hedging Options, FDI transactions",
            },
            {
                head: "Fund Raising/ Borrowing",
                body: "Our solutions empower businesses to achieve their financial goals, from managing working capital to arranging equity investments and bank guarantees",
            }
        ],
    whyUsSection: [
            "Health Check of Existing Treasury Functions",
            "Extensive Hands-on Experience",
            "Best Practices Implementation",
            "Customized Solutions",
            'Consistent Performance'
    ]
}