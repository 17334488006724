import {
  Box,
  Button,
  Typography,
  Grid,
  useMediaQuery,
  Link,
  Container,
} from "@mui/material";
import React from "react";
import serviceConsts from "../../resources/services/const";
import cashFlowBanner from "../../resources/services/cash-flow-banner.png";
import commercialInsuranceBanner from "../../resources/services/commercial-insurance-banner.svg";
import fundsBanner from "../../resources/services/funds-banner.svg";
import bankingBanner from "../../resources/services/banking-banner.svg";
import forexBanner from "../../resources/services/forex-banner.svg";
const ContactButton = () => {
  return (
    <Button
      sx={{
        background: "#154F75",
        borderRadius: "0.4rem",
        py: "1rem",
        px: "1rem",
        mb: 4,
      }}
    >
      <Link
        href="contact"
        style={{
          textDecoration: "none",
        }}
      >
        <Typography
          sx={{
            fontFamily: "Poppins",
            fontWeight: 700,
            color: "#FFFFFF",
          }}
        >
          Contact Now
        </Typography>
      </Link>
    </Button>
  );
};

const TextRenderer = (props) => {
  return (
    <Box>
      <Typography
        sx={{
          fontFamily: "Montserrat",
          fontWeight: 700,
          color: "#154F75",
          mb: 4,
        }}
        variant="h4"
      >
        {props.heading}
      </Typography>
      <Box
        sx={{
          mb: 4,
        }}
      >
        {props.content.map((elem, index) => {
          return (
            <Box
              sx={{
                width: "100%",
                display: "flex",
              }}
            >
              <Box
                sx={{
                  color: "#000000",
                  fontFamily: "Poppins",
                  fontWeight: 700,
                  pr: 2,
                }}
              >
                •
              </Box>

              <Typography
                align="left"
                sx={{
                  fontFamily: "Poppins",
                  fontWeight: 400,
                  color: "#000000",
                }}
              >
                <Box
                  component="span"
                  sx={{
                    fontFamily: "Poppins",
                    fontWeight: 700,
                    color: "#000000",
                  }}
                >
                  {elem.head + "\n"}
                </Box>
                {elem.body}
              </Typography>
            </Box>
          );
        })}
      </Box>
      <ContactButton />
    </Box>
  );
};

const ImageRenderer = (props) => {
  const isBigScreen = useMediaQuery("(min-width: 900px)");
  const isSmallScreen = useMediaQuery("(max-width: 900px)");

  if (props.ind === 0) {
    return (
      <Box
        component={"img"}
        src={cashFlowBanner}
        sx={{
          width: () => {
            if (isBigScreen) {
              return "40vw";
            } else {
              return "70vw";
            }
          },
        }}
      />
    );
  }
  if (props.ind === 1) {
    return (
      <Box
        component={"img"}
        src={commercialInsuranceBanner}
        sx={{
          width: () => {
            if (isBigScreen) {
              return "40vw";
            } else {
              return "70vw";
            }
          },
        }}
      />
    );
  }
  if (props.ind === 2) {
    return (
      <Box
        component={"img"}
        src={fundsBanner}
        sx={{
          width: () => {
            if (isBigScreen) {
              return "40vw";
            } else {
              return "70vw";
            }
          },
        }}
      />
    );
  }
  if (props.ind === 3) {
    return (
      <Box
        component={"img"}
        src={bankingBanner}
        sx={{
          width: () => {
            if (isBigScreen) {
              return "40vw";
            } else {
              return "70vw";
            }
          },
        }}
      />
    );
  }
  if (props.ind === 4) {
    return (
      <Box
        component={"img"}
        src={forexBanner}
        sx={{
          width: () => {
            if (isBigScreen) {
              return "40vw";
            } else {
              return "70vw";
            }
          },
        }}
      />
    );
  }
};

export const MainSection = () => {
  const isBigScreen = useMediaQuery("(min-width: 900px)");
  const isSmallScreen = useMediaQuery("(max-width: 900px)");

  return (
    <Box
      sx={{
        my: 8,
      }}
    >
      <Box>
        {serviceConsts.content.map((elem, index) => {
          if (isBigScreen) {
            return (
              <Grid
                container
                spacing={2}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Grid
                  item
                  xs={5}
                  sx={{
                    ml: 20,
                  }}
                >
                  <TextRenderer heading={elem.head} content={elem.body} />
                </Grid>
                <Grid item sx={5}>
                  <ImageRenderer ind={index} />
                </Grid>
              </Grid>
            );
          } else if(isSmallScreen){
            return (
                    <Container>
                      <Box>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            my: 2,
                          }}
                        >
                          <ImageRenderer ind={index} />
                        </Box>
                        <TextRenderer heading={elem.head} content={elem.body} />
                      </Box>
                    </Container>
                  
            );
          }
        })}
      </Box>
    </Box>
  );
};
