import React from "react";
import { Box, Container, Typography, Button, Link, useMediaQuery } from "@mui/material";
import { NavBar } from "../components/navbar";
import { FormSection } from "../components/careers/form-section";
import "../css/career.css";

export const Careers = () => {
  const isBiggerScreen = useMediaQuery("(min-width: 900px)");
  return (
    <Box>
      <Box className="career-parent">
        <NavBar />
        <Box>
          <Container fixed>
            <Box
              sx={{
                pt: ()=>{
                  if(isBiggerScreen){
                    return 16
                  }else{
                    return 4
                  }
                },
                pb: 8
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Inria Sans",
                  fontWeight: 700,
                  color: "#FFFFFF",
                  mb: 4,
                }}
                variant="h3"
              >
                Make Career with Us
              </Typography>
              <Typography
                sx={{
                  fontFamily: "Inria Sans",
                  fontWeight: 400,
                  color: "#FFFFFF",
                }}
                variant="h5"
              >
                At Worthy Advisors, we believe our success is built by each one
                of us contributing each day to achieve our common vision &
                dream. We are always looking for great talent and passionate
                folks to join our team. If honesty, integrity, discipline, and
                responsibility match your characteristics, write to us today at
                connect@worthyadvisors.in or fill up the form to send us your
                resume.
              </Typography>
              <Box
                sx={{
                  mt: 4,
                }}
              >
                <Link
                  href="#forms"
                  style={{
                    textDecoration: "none",
                    color: "white",
                  }}
                >
                  <Button
                    sx={{
                      fontFamily: "Poppins",
                      fontWeight: 400,
                      color: "#FFFFFF",
                      background: "#154f75",
                      px: 8,
                    }}
                  >
                    Join Us
                  </Button>
                </Link>
              </Box>
            </Box>
          </Container>
        </Box>
      </Box>
      <Container id="forms">
        <FormSection />
      </Container>
    </Box>
  );
};
