import React, { useState, useEffect } from "react";
import { Box, Container, Link, Typography, useMediaQuery } from "@mui/material";
import { NavBar } from "../components/navbar";
// import { BlogSection } from "../components/blogs/blogSection";
import highlightBlogBanner from "../resources/blogs/highlight-blog-banner.svg";
import "../css/blogs.css";
import { useParams } from "react-router-dom";

const BlogCardPage = (props) => {
  const isBigScreen = useMediaQuery("(min-width: 900px)");
  const isSmallerScreen = useMediaQuery("(max-width: 500px)");

  return (
    <Box>
      <Box className="blog-parent">
        <NavBar />
        <Box>
          <Container fixed>
            <Box
              sx={{
                py: 4,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: () => {
                  if (isBigScreen) {
                    return "row";
                  } else {
                    return "column";
                  }
                },
              }}
            >
              <Box
                component={"img"}
                src={props.img}
                sx={{
                  height: 350,
                  width: () => {
                    if (isBigScreen) {
                      return "33%";
                    } else {
                      return "80vw";
                    }
                  },
                  mr: () => {
                    if (isBigScreen) {
                      return "96px";
                    }
                  },
                }}
              />
              <Box>
                <Typography
                  sx={{
                    fontFamily: "Poppins",
                    fontWeight: 700,
                    color: "#FFFFFF",
                    my: 2,
                  }}
                  variant="h4"
                >
                  {props.heading}
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "Poppins",
                    fontWeight: 400,
                    color: "#FFFFFF",
                    my: 2,
                  }}
                  variant="h5"
                >
                  {props.summary}
                </Typography>

                <Link
                  sx={{
                    textDecoration: "none",
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "Poppins",
                      fontWeight: 400,
                      color: "#FFFFFF",
                      my: 2,
                    }}
                    variant="h6"
                  ></Typography>
                </Link>
              </Box>
            </Box>
          </Container>
        </Box>
      </Box>
      <Container id="blogs">
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: () => {
              if (isSmallerScreen) {
                return "center";
              } else {
                return "space-evenly";
              }
            },
            alignItems: "center",
            py: 8,
          }}
        >
          <div dangerouslySetInnerHTML={{ __html: props.content }}></div>
        </Box>
      </Container>
    </Box>
  );
};

export const Blog = () => {
  // const [posts, setPosts] = useState([]);
  // useEffect(() => {
  //   fetch("https://worthyadvisors.onrender.com/post").then((response) => {
  //     response.json().then((posts) => {
  //       setPosts(posts);
  //     });
  //   });
  // }, []);

  const [postInfo, setPostInfo] = useState(null);
  // const { userInfo } = useContext(UserContext);
  const { id } = useParams();
  useEffect(() => {
    fetch(`https://worthyadvisors.onrender.com/posts/${id}`).then(
      (response) => {
        response.json().then((postInfo) => {
          setPostInfo(postInfo);
        });
      }
    );
  }, []);

  if (!postInfo) return "";

  return (
    <box>
      <BlogCardPage
        img={"https://worthyadvisors.onrender.com/" + postInfo.cover}
        heading={postInfo.title}
        summary={postInfo.summary}
        content={postInfo.content}
      />
    </box>
  );
};
