import React, { useState } from "react";

import { Box, Typography, useMediaQuery, Paper, Link } from "@mui/material";

import { homeConsts } from "../../resources/home/const";
import cashFlowIcon from '../../resources/home/cash-flow-icon.svg'
import insuranceIcon from '../../resources/home/insurance-icon.svg'
import investmentIcon from '../../resources/home/investment-icon.svg'
import bankingIcon from '../../resources/home/banking-icon.svg'
import forexIcon from '../../resources/home/forex-icon.svg'
import fundIcon from '../../resources/home/fund-icon.svg'

export const ServiceSection = () => {

    const [contentArray, setContentArray] = useState(homeConsts.ourServicesSection)
    const twoRows = useMediaQuery('(min-width:900px)');
    const threeRows = useMediaQuery('(min-width:600px) and (max-width:900px)');
    const sixRows = useMediaQuery('(max-width:600px)')
    return (
        <Box
            sx={{
                py: 8
            }}
        >
            <Box
                sx={{
                    pb: 4,
                }}
            >
                <Typography
                    align="left"
                    gutterBottom="true"
                    variant="h4"
                    sx={{
                        fontFamily: 'Poppins',
                        fontWeight: 500,
                    }}
                >
                    Our Services
                </Typography>
            </Box>
            {
                twoRows &&
                    <Box>
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                mb: 3,
                            }}
                        >
                            <Paper
                                elevation={0}
                                sx={{
                                    p: 4,
                                    borderRadius: 0,
                                    boxShadow: '0px 0px 2rem 0px rgba(10, 10, 10, 0.07)',
                                    width: '20vw',
                                    display: 'flex',
                                    alignItems: 'center',
                                    flexDirection: 'column'
                                }}
                            >
                                <Box
                                    component='img'
                                    src={cashFlowIcon}
                                />
                                <Box>
                                    <Typography
                                        sx={{
                                            fontFamily: 'Montserrat',
                                            fontWeight: 700,
                                            fontSize: '1.2rem',
                                            pt: 1,
                                            color: '#154F75',
                                        }}
                                        align="center"
                                    >
                                        Cash Flow
                                    </Typography>
                                    <Typography
                                        sx={{
                                            fontFamily: 'Montserrat',
                                            fontWeight: 700,
                                            fontSize: '1.2rem',
                                            pb: 1,
                                            color: '#154F75',
                                        }}
                                        align="center"
                                    >
                                        Management
                                    </Typography>
                                </Box>
                                <Typography
                                    sx={{
                                        fontFamily: 'Open Sans',
                                        fontWeight: 400,
                                        fontSize: '1rem'
                                    }}
                                    align="center"
                                >
                                    {contentArray[0].body}
                                </Typography>
                                <Link
                                    href="/services"
                                    sx={{
                                        textDecoration: "none",
                                        fontFamily: 'Montserrat',
                                        fontWeight: 700,
                                        fontSize: '1rem',
                                        color: '#154F75',
                                        mt: 2
                                    }}
                                >
                                    Read More
                                </Link>
                            </Paper>
                            <Paper
                                elevation={0}
                                sx={{
                                    p: 4,
                                    borderRadius: 0,
                                    boxShadow: '0px 0px 2rem 0px rgba(10, 10, 10, 0.07)',
                                    width: '20vw',
                                    display: 'flex',
                                    alignItems: 'center',
                                    flexDirection: 'column'
                                }}
                            >
                                <Box
                                    component='img'
                                    src={insuranceIcon}
                                />
                                <Box>
                                    <Typography
                                        sx={{
                                            fontFamily: 'Montserrat',
                                            fontWeight: 700,
                                            fontSize: '1.2rem',
                                            pt: 1,
                                            color: '#154F75',
                                        }}
                                        align="center"
                                    >
                                        Commercial
                                    </Typography>
                                    <Typography
                                        sx={{
                                            fontFamily: 'Montserrat',
                                            fontWeight: 700,
                                            fontSize: '1.2rem',
                                            pb: 1,
                                            color: '#154F75',
                                        }}
                                        align="center"
                                    >
                                        Insurance
                                    </Typography> 
                                </Box>
                                <Typography
                                    sx={{
                                        fontFamily: 'Open Sans',
                                        fontWeight: 400,
                                        fontSize: '1rem'
                                    }}
                                    align="center"
                                >
                                    {contentArray[1].body}
                                </Typography>
                                <Link
                                    href="/services"
                                    sx={{
                                        textDecoration: "none",
                                        fontFamily: 'Montserrat',
                                        fontWeight: 700,
                                        fontSize: '1rem',
                                        color: '#154F75',
                                        mt: 2
                                    }}
                                >
                                    Read More
                                </Link>
                            </Paper>
                            <Paper
                                elevation={0}
                                sx={{
                                    p: 4,
                                    borderRadius: 0,
                                    boxShadow: '0px 0px 2rem 0px rgba(10, 10, 10, 0.07)',
                                    width: '20vw',
                                    display: 'flex',
                                    alignItems: 'center',
                                    flexDirection: 'column'
                                }}
                            >
                                <Box
                                    component='img'
                                    src={investmentIcon}
                                />
                                <Box>
                                    <Typography
                                        sx={{
                                            fontFamily: 'Montserrat',
                                            fontWeight: 700,
                                            fontSize: '1.2rem',
                                            pt: 1,
                                            color: '#154F75',
                                        }}
                                        align="center"
                                    >
                                        Corporate
                                    </Typography>
                                    <Typography
                                        sx={{
                                            fontFamily: 'Montserrat',
                                            fontWeight: 700,
                                            fontSize: '1.2rem',
                                            pb: 1,
                                            color: '#154F75',
                                        }}
                                        align="center"
                                    >
                                        Investments
                                    </Typography> 
                                </Box>
                                <Typography
                                    sx={{
                                        fontFamily: 'Open Sans',
                                        fontWeight: 400,
                                        fontSize: '1rem'
                                    }}
                                    align="center"
                                >
                                    {contentArray[2].body}
                                </Typography>
                                <Link
                                    href="/services"
                                    sx={{
                                        textDecoration: "none",
                                        fontFamily: 'Montserrat',
                                        fontWeight: 700,
                                        fontSize: '1rem',
                                        color: '#154F75',
                                        mt: 2
                                    }}
                                >
                                    Read More
                                </Link>
                            </Paper>
                        </Box>
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "space-between"
                            }}
                        >
                            <Paper
                                elevation={0}
                                sx={{
                                    p: 4,
                                    borderRadius: 0,
                                    boxShadow: '0px 0px 2rem 0px rgba(10, 10, 10, 0.07)',
                                    width: '20vw',
                                    display: 'flex',
                                    alignItems: 'center',
                                    flexDirection: 'column'
                                }}
                            >
                                <Box
                                    component='img'
                                    src={bankingIcon}
                                />
                                <Box>
                                    <Typography
                                        sx={{
                                            fontFamily: 'Montserrat',
                                            fontWeight: 700,
                                            fontSize: '1.2rem',
                                            py: 1,
                                            color: '#154F75',
                                        }}
                                        align="center"
                                    >
                                        Corporate Banking
                                    </Typography>
                                </Box>
                                <Typography
                                    sx={{
                                        fontFamily: 'Open Sans',
                                        fontWeight: 400,
                                        fontSize: '1rem'
                                    }}
                                    align="center"
                                >
                                    {contentArray[3].body}
                                </Typography>
                                <Link
                                    href="/services"
                                    sx={{
                                        textDecoration: "none",
                                        fontFamily: 'Montserrat',
                                        fontWeight: 700,
                                        fontSize: '1rem',
                                        color: '#154F75',
                                        mt: 2
                                    }}
                                >
                                    Read More
                                </Link>
                            </Paper>
                            <Paper
                                elevation={0}
                                sx={{
                                    p: 4,
                                    borderRadius: 0,
                                    boxShadow: '0px 0px 2rem 0px rgba(10, 10, 10, 0.07)',
                                    width: '20vw',
                                    display: 'flex',
                                    alignItems: 'center',
                                    flexDirection: 'column'
                                }}
                            >
                                <Box
                                    component='img'
                                    src={forexIcon}
                                />
                                <Box>
                                    <Typography
                                        sx={{
                                            fontFamily: 'Montserrat',
                                            fontWeight: 700,
                                            fontSize: '1.2rem',
                                            pt: 1,
                                            color: '#154F75',
                                        }}
                                        align="center"
                                    >
                                        Forex
                                    </Typography>
                                    <Typography
                                        sx={{
                                            fontFamily: 'Montserrat',
                                            fontWeight: 700,
                                            fontSize: '1.2rem',
                                            pb: 1,
                                            color: '#154F75',
                                        }}
                                        align="center"
                                    >
                                        Management
                                    </Typography>
                                </Box>
                                <Typography
                                    sx={{
                                        fontFamily: 'Open Sans',
                                        fontWeight: 400,
                                        fontSize: '1rem'
                                    }}
                                    align="center"
                                >
                                    {contentArray[4].body}
                                </Typography>
                                <Link
                                    href="/services"
                                    sx={{
                                        textDecoration: "none",
                                        fontFamily: 'Montserrat',
                                        fontWeight: 700,
                                        fontSize: '1rem',
                                        color: '#154F75',
                                        mt: 2
                                    }}
                                >
                                    Read More
                                </Link>
                            </Paper>
                            <Paper
                                elevation={0}
                                sx={{
                                    p: 4,
                                    borderRadius: 0,
                                    boxShadow: '0px 0px 2rem 0px rgba(10, 10, 10, 0.07)',
                                    width: '20vw',
                                    display: 'flex',
                                    alignItems: 'center',
                                    flexDirection: 'column'
                                }}
                            >
                                <Box
                                    component='img'
                                    src={fundIcon}
                                />
                                <Box>
                                    <Typography
                                        sx={{
                                            fontFamily: 'Montserrat',
                                            fontWeight: 700,
                                            fontSize: '1.2rem',
                                            py: 1,
                                            color: '#154F75',
                                        }}
                                        align="center"
                                    >
                                        Fund Raising/Borrowing
                                    </Typography>
                                </Box>
                                <Typography
                                    sx={{
                                        fontFamily: 'Open Sans',
                                        fontWeight: 400,
                                        fontSize: '1rem'
                                    }}
                                    align="center"
                                >
                                    {contentArray[5].body}
                                </Typography>
                                <Link
                                    href="/services"
                                    sx={{
                                        textDecoration: "none",
                                        fontFamily: 'Montserrat',
                                        fontWeight: 700,
                                        fontSize: '1rem',
                                        color: '#154F75',
                                        mt: 2
                                    }}
                                >
                                    Read More
                                </Link>
                            </Paper>
                        </Box>
                    </Box>
            }
            {
                threeRows &&
                    <Box>
                        <Box
                            sx={{
                                display: "flex",
                                flexWrap: "wrap",
                                justifyContent: "space-evenly",
                                pb: 2
                            }}
                        >
                            <Paper
                                elevation={0}
                                sx={{
                                    p: 4,
                                    borderRadius: 0,
                                    boxShadow: '0px 0px 2rem 0px rgba(10, 10, 10, 0.07)',
                                    width: '25vw',
                                    display: 'flex',
                                    alignItems: 'center',
                                    flexDirection: 'column'
                                }}
                            >
                                <Box
                                    component='img'
                                    src={cashFlowIcon}
                                />
                                <Box>
                                    <Typography
                                        sx={{
                                            fontFamily: 'Montserrat',
                                            fontWeight: 700,
                                            fontSize: '1.2rem',
                                            pt: 1,
                                            color: '#154F75',
                                        }}
                                        align="center"
                                    >
                                        Cash Flow
                                    </Typography>
                                    <Typography
                                        sx={{
                                            fontFamily: 'Montserrat',
                                            fontWeight: 700,
                                            fontSize: '1.2rem',
                                            pb: 1,
                                            color: '#154F75',
                                        }}
                                        align="center"
                                    >
                                        Management
                                    </Typography>
                                </Box>
                                <Typography
                                    sx={{
                                        fontFamily: 'Open Sans',
                                        fontWeight: 400,
                                        fontSize: '1rem'
                                    }}
                                    align="center"
                                >
                                    {contentArray[0].body}
                                </Typography>
                                <Link
                                    href="/services"
                                    sx={{
                                        textDecoration: "none",
                                        fontFamily: 'Montserrat',
                                        fontWeight: 700,
                                        fontSize: '1rem',
                                        color: '#154F75',
                                        mt: 2
                                    }}
                                >
                                    Read More
                                </Link>
                            </Paper>
                            <Paper
                                elevation={0}
                                sx={{
                                    p: 4,
                                    borderRadius: 0,
                                    boxShadow: '0px 0px 2rem 0px rgba(10, 10, 10, 0.07)',
                                    width: '25vw',
                                    display: 'flex',
                                    alignItems: 'center',
                                    flexDirection: 'column'
                                }}
                            >
                                <Box
                                    component='img'
                                    src={insuranceIcon}
                                />
                                <Box>
                                    <Typography
                                        sx={{
                                            fontFamily: 'Montserrat',
                                            fontWeight: 700,
                                            fontSize: '1.2rem',
                                            pt: 1,
                                            color: '#154F75',
                                        }}
                                        align="center"
                                    >
                                        Commercial
                                    </Typography>
                                    <Typography
                                        sx={{
                                            fontFamily: 'Montserrat',
                                            fontWeight: 700,
                                            fontSize: '1.2rem',
                                            pb: 1,
                                            color: '#154F75',
                                        }}
                                        align="center"
                                    >
                                        Insurance
                                    </Typography> 
                                </Box>
                                <Typography
                                    sx={{
                                        fontFamily: 'Open Sans',
                                        fontWeight: 400,
                                        fontSize: '1rem'
                                    }}
                                    align="center"
                                >
                                    {contentArray[1].body}
                                </Typography>
                                <Link
                                    href="/services"
                                    sx={{
                                        textDecoration: "none",
                                        fontFamily: 'Montserrat',
                                        fontWeight: 700,
                                        fontSize: '1rem',
                                        color: '#154F75',
                                        mt: 2
                                    }}
                                >
                                    Read More
                                </Link>
                            </Paper>
                        </Box>
                        <Box
                            sx={{
                                display: "flex",
                                flexWrap: "wrap",
                                justifyContent: "space-evenly",
                                pb: 2,
                            }}
                        >
                            <Paper
                                elevation={0}
                                sx={{
                                    p: 4,
                                    borderRadius: 0,
                                    boxShadow: '0px 0px 2rem 0px rgba(10, 10, 10, 0.07)',
                                    width: '25vw',
                                    display: 'flex',
                                    alignItems: 'center',
                                    flexDirection: 'column'
                                }}
                            >
                                <Box
                                    component='img'
                                    src={investmentIcon}
                                />
                                <Box>
                                    <Typography
                                        sx={{
                                            fontFamily: 'Montserrat',
                                            fontWeight: 700,
                                            fontSize: '1.2rem',
                                            pt: 1,
                                            color: '#154F75',
                                        }}
                                        align="center"
                                    >
                                        Corporate
                                    </Typography>
                                    <Typography
                                        sx={{
                                            fontFamily: 'Montserrat',
                                            fontWeight: 700,
                                            fontSize: '1.2rem',
                                            pb: 1,
                                            color: '#154F75',
                                        }}
                                        align="center"
                                    >
                                        Investments
                                    </Typography> 
                                </Box>
                                <Typography
                                    sx={{
                                        fontFamily: 'Open Sans',
                                        fontWeight: 400,
                                        fontSize: '1rem'
                                    }}
                                    align="center"
                                >
                                    {contentArray[2].body}
                                </Typography>
                                <Link
                                    href="/services"
                                    sx={{
                                        textDecoration: "none",
                                        fontFamily: 'Montserrat',
                                        fontWeight: 700,
                                        fontSize: '1rem',
                                        color: '#154F75',
                                        mt: 2
                                    }}
                                >
                                    Read More
                                </Link>
                            </Paper>
                            <Paper
                                elevation={0}
                                sx={{
                                    p: 4,
                                    borderRadius: 0,
                                    boxShadow: '0px 0px 2rem 0px rgba(10, 10, 10, 0.07)',
                                    width: '25vw',
                                    display: 'flex',
                                    alignItems: 'center',
                                    flexDirection: 'column'
                                }}
                            >
                                <Box
                                    component='img'
                                    src={bankingIcon}
                                />
                                <Box>
                                    <Typography
                                        sx={{
                                            fontFamily: 'Montserrat',
                                            fontWeight: 700,
                                            fontSize: '1.2rem',
                                            py: 1,
                                            color: '#154F75',
                                        }}
                                        align="center"
                                    >
                                        Corporate Banking
                                    </Typography>
                                </Box>
                                <Typography
                                    sx={{
                                        fontFamily: 'Open Sans',
                                        fontWeight: 400,
                                        fontSize: '1rem'
                                    }}
                                    align="center"
                                >
                                    {contentArray[3].body}
                                </Typography>
                                <Link
                                    href="/services"
                                    sx={{
                                        textDecoration: "none",
                                        fontFamily: 'Montserrat',
                                        fontWeight: 700,
                                        fontSize: '1rem',
                                        color: '#154F75',
                                        mt: 2
                                    }}
                                >
                                    Read More
                                </Link>
                            </Paper>
                        </Box>
                        <Box
                            sx={{
                                display: "flex",
                                flexWrap: "wrap",
                                justifyContent: "space-evenly"
                            }}
                        >
                            <Paper
                                elevation={0}
                                sx={{
                                    p: 4,
                                    borderRadius: 0,
                                    boxShadow: '0px 0px 2rem 0px rgba(10, 10, 10, 0.07)',
                                    width: '25vw',
                                    display: 'flex',
                                    alignItems: 'center',
                                    flexDirection: 'column'
                                }}
                            >
                                <Box
                                    component='img'
                                    src={forexIcon}
                                />
                                <Box>
                                    <Typography
                                        sx={{
                                            fontFamily: 'Montserrat',
                                            fontWeight: 700,
                                            fontSize: '1.2rem',
                                            pt: 1,
                                            color: '#154F75',
                                        }}
                                        align="center"
                                    >
                                        Forex
                                    </Typography>
                                    <Typography
                                        sx={{
                                            fontFamily: 'Montserrat',
                                            fontWeight: 700,
                                            fontSize: '1.2rem',
                                            pb: 1,
                                            color: '#154F75',
                                        }}
                                        align="center"
                                    >
                                        Management
                                    </Typography>
                                </Box>
                                <Typography
                                    sx={{
                                        fontFamily: 'Open Sans',
                                        fontWeight: 400,
                                        fontSize: '1rem'
                                    }}
                                    align="center"
                                >
                                    {contentArray[4].body}
                                </Typography>
                                <Link
                                    href="/services"
                                    sx={{
                                        textDecoration: "none",
                                        fontFamily: 'Montserrat',
                                        fontWeight: 700,
                                        fontSize: '1rem',
                                        color: '#154F75',
                                        mt: 2
                                    }}
                                >
                                    Read More
                                </Link>
                            </Paper>
                            <Paper
                                elevation={0}
                                sx={{
                                    p: 4,
                                    borderRadius: 0,
                                    boxShadow: '0px 0px 2rem 0px rgba(10, 10, 10, 0.07)',
                                    width: '25vw',
                                    display: 'flex',
                                    alignItems: 'center',
                                    flexDirection: 'column'
                                }}
                            >
                                <Box
                                    component='img'
                                    src={fundIcon}
                                />
                                <Box>
                                    <Typography
                                        sx={{
                                            fontFamily: 'Montserrat',
                                            fontWeight: 700,
                                            fontSize: '1.2rem',
                                            py: 1,
                                            color: '#154F75',
                                        }}
                                        align="center"
                                    >
                                        Fund Raising/Borrowing
                                    </Typography>
                                </Box>
                                <Typography
                                    sx={{
                                        fontFamily: 'Open Sans',
                                        fontWeight: 400,
                                        fontSize: '1rem'
                                    }}
                                    align="center"
                                >
                                    {contentArray[5].body}
                                </Typography>
                                <Link
                                    href="/services"
                                    sx={{
                                        textDecoration: "none",
                                        fontFamily: 'Montserrat',
                                        fontWeight: 700,
                                        fontSize: '1rem',
                                        color: '#154F75',
                                        mt: 2
                                    }}
                                >
                                    Read More
                                </Link>
                            </Paper>
                        </Box>
                    </Box>
            }
            {
                sixRows &&
                
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: 'center',
                        }}
                    >
                        <Paper
                            elevation={0}
                            sx={{
                                p: 4,
                                mb: 2,
                                borderRadius: 0,
                                boxShadow: '0px 0px 2rem 0px rgba(10, 10, 10, 0.07)',
                                display: 'flex',
                                alignItems: 'center',
                                flexDirection: 'column'
                            }}
                        >
                            <Box
                                component='img'
                                src={cashFlowIcon}
                            />
                            <Box>
                                <Typography
                                    sx={{
                                        fontFamily: 'Montserrat',
                                        fontWeight: 700,
                                        fontSize: '1.2rem',
                                        pt: 1,
                                        color: '#154F75',
                                    }}
                                    align="center"
                                >
                                    Cash Flow
                                </Typography>
                                <Typography
                                    sx={{
                                        fontFamily: 'Montserrat',
                                        fontWeight: 700,
                                        fontSize: '1.2rem',
                                        pb: 1,
                                        color: '#154F75',
                                    }}
                                    align="center"
                                >
                                    Management
                                </Typography>
                            </Box>
                            <Typography
                                sx={{
                                    fontFamily: 'Open Sans',
                                    fontWeight: 400,
                                    fontSize: '1rem'
                                }}
                                align="center"
                            >
                                {contentArray[0].body}
                            </Typography>
                            <Link
                                    href="/services"
                                    sx={{
                                        textDecoration: "none",
                                        fontFamily: 'Montserrat',
                                        fontWeight: 700,
                                        fontSize: '1rem',
                                        color: '#154F75',
                                        mt: 2
                                    }}
                                >
                                    Read More
                                </Link>
                        </Paper>
                        <Paper
                            elevation={0}
                            sx={{
                                p: 4,
                                borderRadius: 0,
                                boxShadow: '0px 0px 2rem 0px rgba(10, 10, 10, 0.07)',
                                mb: 2,
                                display: 'flex',
                                alignItems: 'center',
                                flexDirection: 'column'
                            }}
                        >
                            <Box
                                component='img'
                                src={insuranceIcon}
                            />
                            <Box>
                                <Typography
                                    sx={{
                                        fontFamily: 'Montserrat',
                                        fontWeight: 700,
                                        fontSize: '1.2rem',
                                        pt: 1,
                                        color: '#154F75',
                                    }}
                                    align="center"
                                >
                                    Commercial
                                </Typography>
                                <Typography
                                    sx={{
                                        fontFamily: 'Montserrat',
                                        fontWeight: 700,
                                        fontSize: '1.2rem',
                                        pb: 1,
                                        color: '#154F75',
                                    }}
                                    align="center"
                                >
                                    Insurance
                                </Typography> 
                            </Box>
                            <Typography
                                sx={{
                                    fontFamily: 'Open Sans',
                                    fontWeight: 400,
                                    fontSize: '1rem'
                                }}
                                align="center"
                            >
                                {contentArray[1].body}
                            </Typography>
                            <Link
                                    href="/services"
                                    sx={{
                                        textDecoration: "none",
                                        fontFamily: 'Montserrat',
                                        fontWeight: 700,
                                        fontSize: '1rem',
                                        color: '#154F75',
                                        mt: 2
                                    }}
                                >
                                    Read More
                                </Link>
                        </Paper>
                        <Paper
                            elevation={0}
                            sx={{
                                p: 4,
                                borderRadius: 0,
                                boxShadow: '0px 0px 2rem 0px rgba(10, 10, 10, 0.07)',
                                mb:2 ,
                                display: 'flex',
                                alignItems: 'center',
                                flexDirection: 'column'
                            }}
                        >
                            <Box
                                component='img'
                                src={investmentIcon}
                            />
                            <Box>
                                <Typography
                                    sx={{
                                        fontFamily: 'Montserrat',
                                        fontWeight: 700,
                                        fontSize: '1.2rem',
                                        pt: 1,
                                        color: '#154F75',
                                    }}
                                    align="center"
                                >
                                    Corporate
                                </Typography>
                                <Typography
                                    sx={{
                                        fontFamily: 'Montserrat',
                                        fontWeight: 700,
                                        fontSize: '1.2rem',
                                        pb: 1,
                                        color: '#154F75',
                                    }}
                                    align="center"
                                >
                                    Investments
                                </Typography> 
                            </Box>
                            <Typography
                                sx={{
                                    fontFamily: 'Open Sans',
                                    fontWeight: 400,
                                    fontSize: '1rem'
                                }}
                                align="center"
                            >
                                {contentArray[2].body}
                            </Typography>
                            <Link
                                    href="/services"
                                    sx={{
                                        textDecoration: "none",
                                        fontFamily: 'Montserrat',
                                        fontWeight: 700,
                                        fontSize: '1rem',
                                        color: '#154F75',
                                        mt: 2
                                    }}
                                >
                                    Read More
                                </Link>
                        </Paper>
                        <Paper
                            elevation={0}
                            sx={{
                                p: 4,
                                borderRadius: 0,
                                boxShadow: '0px 0px 2rem 0px rgba(10, 10, 10, 0.07)',
                                mb: 2,
                                display: 'flex',
                                alignItems: 'center',
                                flexDirection: 'column'
                            }}
                        >
                            <Box
                                component='img'
                                src={bankingIcon}
                            />
                            <Box>
                                <Typography
                                    sx={{
                                        fontFamily: 'Montserrat',
                                        fontWeight: 700,
                                        fontSize: '1.2rem',
                                        py: 1,
                                        color: '#154F75',
                                    }}
                                    align="center"
                                >
                                    Corporate Banking
                                </Typography>
                            </Box>
                            <Typography
                                sx={{
                                    fontFamily: 'Open Sans',
                                    fontWeight: 400,
                                    fontSize: '1rem'
                                }}
                                align="center"
                            >
                                {contentArray[3].body}
                            </Typography>
                            <Link
                                    href="/services"
                                    sx={{
                                        textDecoration: "none",
                                        fontFamily: 'Montserrat',
                                        fontWeight: 700,
                                        fontSize: '1rem',
                                        color: '#154F75',
                                        mt: 2
                                    }}
                                >
                                    Read More
                                </Link>
                        </Paper>
                        <Paper
                            elevation={0}
                            sx={{
                                p: 4,
                                borderRadius: 0,
                                boxShadow: '0px 0px 2rem 0px rgba(10, 10, 10, 0.07)',
                                mb: 2,
                                display: 'flex',
                                alignItems: 'center',
                                flexDirection: 'column'
                            }}
                        >
                            <Box
                                component='img'
                                src={forexIcon}
                            />
                            <Box>
                                <Typography
                                    sx={{
                                        fontFamily: 'Montserrat',
                                        fontWeight: 700,
                                        fontSize: '1.2rem',
                                        pt: 1,
                                        color: '#154F75',
                                    }}
                                    align="center"
                                >
                                    Forex
                                </Typography>
                                <Typography
                                    sx={{
                                        fontFamily: 'Montserrat',
                                        fontWeight: 700,
                                        fontSize: '1.2rem',
                                        pb: 1,
                                        color: '#154F75',
                                    }}
                                    align="center"
                                >
                                    Management
                                </Typography>
                            </Box>
                            <Typography
                                sx={{
                                    fontFamily: 'Open Sans',
                                    fontWeight: 400,
                                    fontSize: '1rem'
                                }}
                                align="center"
                            >
                                {contentArray[4].body}
                            </Typography>
                            <Link
                                    href="/services"
                                    sx={{
                                        textDecoration: "none",
                                        fontFamily: 'Montserrat',
                                        fontWeight: 700,
                                        fontSize: '1rem',
                                        color: '#154F75',
                                        mt: 2
                                    }}
                                >
                                    Read More
                                </Link>
                        </Paper>
                        <Paper
                            elevation={0}
                            sx={{
                                p: 4,
                                borderRadius: 0,
                                boxShadow: '0px 0px 2rem 0px rgba(10, 10, 10, 0.07)',
                                display: 'flex',
                                alignItems: 'center',
                                flexDirection: 'column'
                            }}
                        >
                            <Box
                                component='img'
                                src={fundIcon}
                            />
                            <Box>
                                <Typography
                                    sx={{
                                        fontFamily: 'Montserrat',
                                        fontWeight: 700,
                                        fontSize: '1.2rem',
                                        py: 1,
                                        color: '#154F75',
                                    }}
                                    align="center"
                                >
                                    Fund Raising/Borrowing
                                </Typography>
                            </Box>
                            <Typography
                                sx={{
                                    fontFamily: 'Open Sans',
                                    fontWeight: 400,
                                    fontSize: '1rem'
                                }}
                                align="center"
                            >
                                {contentArray[5].body}
                            </Typography>
                            <Link
                                    href="/services"
                                    sx={{
                                        textDecoration: "none",
                                        fontFamily: 'Montserrat',
                                        fontWeight: 700,
                                        fontSize: '1rem',
                                        color: '#154F75',
                                        mt: 2
                                    }}
                                >
                                    Read More
                                </Link>
                        </Paper>
                </Box>
            }
        </Box>
    )
}