import React from "react";
import founderCard from "../../resources/contact/founder-pic.svg";
import { Box, Container, Typography, useMediaQuery, Link } from "@mui/material";
export const FounderSection = () => {
  const isBigScreen = useMediaQuery("(min-width: 800px)");
  const isSmallScreen = useMediaQuery("(max-width: 800px)");
  return (
    <Container>
      {isBigScreen && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              fontFamily: "Poppins",
              fontWeight: 400,
              color: "#FFFFFF",
            }}
            variant="h3"
          >
            Meet Our
            <Box
              component={"span"}
              sx={{
                fontFamily: "Poppins",
                fontWeight: 700,
                color: "#FFFFFF",
              }}
            >
              {"\n Founder"}
            </Box>
          </Typography>
          <Link href="https://www.linkedin.com/in/kunal-sheth-11a6a618/">
            <Box component={"img"} src={founderCard} />
          </Link>
        </Box>
      )}
      {isSmallScreen && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Typography
            sx={{
              fontFamily: "Poppins",
              fontWeight: 400,
              color: "#FFFFFF",
            }}
            variant="h3"
            align="center"
          >
            Meet Our
            <Box
              component={"span"}
              sx={{
                fontFamily: "Poppins",
                fontWeight: 700,
                color: "#FFFFFF",
              }}
            >
              {"\n Founder"}
            </Box>
          </Typography>
          <Box
            component={"img"}
            src={founderCard}
            sx={{
              width: "80vw",
            }}
          />
        </Box>
      )}
    </Container>
  );
};
