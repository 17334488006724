import React from "react";
import { Box, Container, Typography } from "@mui/material";
import { NavBar } from "../components/navbar";
import { MainSection } from "../components/service/mainSection";
import "../css/service.css";
export const Services = () => {
  return (
    <Box>
      <Box className="service-parent">
        <NavBar />
        <Box>
          <Container fixed>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                py: 16,
                justifyContent: "center",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Inria Sans",
                  fontWeight: 700,
                  color: "#FFFFFF",
                  mb: 4,
                }}
                variant="h3"
              >
                We make your treasury worthy !
              </Typography>
              <Typography
                sx={{
                  fontFamily: "Inria Sans",
                  fontWeight: 400,
                  color: "#FFFFFF",
                }}
                variant="h5"
              >
                We believe in the power of the treasury function to drive
                positive change for businesses of all sizes. Worthy Advisors can
                help your treasury function evolve faster while responding to
                changing market conditions and become a strategic partner. We'll
                handle your daily cash flow forecasting, your investment
                management, and insurance, and help you out in your fundraising
                and risk mitigation.
              </Typography>
            </Box>
          </Container>
        </Box>
      </Box>
      <MainSection />
    </Box>
  );
};
