import React from "react";
import { Box, Container } from "@mui/material";
import { NavBar } from "../components/navbar";
import { FounderSection } from "../components/contact/founderSection";
import { ContactForm } from "../components/contact/contactForm";
import '../css/contact.css'

export const Contact = () => {
    return(
        <Box>
            <Box className="contact-parent">
                <NavBar />
                <Box>
                    <Container fixed>
                        <ContactForm />
                    </Container>
                </Box>
            </Box>
            <Box
                className="founder-container-parent"
                sx={{
                    py: 4
                }}
            >
                <FounderSection />
            </Box>
        </Box>
    )
}