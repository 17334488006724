import React, { useRef } from "react";
import emailjs from "@emailjs/browser";
import {
  Box,
  FormControl,
  Grid,
  Input,
  Typography,
  Button,
  useMediaQuery,
} from "@mui/material";
import { Link } from "@mui/material";
import reachUsBanner from "../../resources/careers/reach-us-banner.svg";
import careerCompanyLogo from "../../resources/careers/career-company-logo.svg";
import uploadIcon from "../../resources/careers/upload-icon.svg";
import { TextareaAutosize } from "@mui/base/TextareaAutosize";
export const FormSection = () => {
  const isLargeScreen = useMediaQuery("(min-width: 900px)");
  const isSmallScreen = useMediaQuery("(max-width: 900px)");
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    // btn.value = "Sending...";
    emailjs
      .sendForm(
        "service_5tf4w5k",
        "template_e5qrkc8",
        form.current,
        "KXHDeY1Z2zqrT9dR5"
      )
      .then(
        (result) => {
          console.log(result.text);
          console.log("message sent");
          //   btn.value = "SUBMIT";
          alert("Submitted!");
          //   btn.value = "SUBMIT";
          e.target.reset();
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  const handleUploadFile=()=>{
    const fileInput = document.getElementById("fileInput")
    fileInput.click()
  }

  return (
    <Box
      sx={{
        mb: 8,
        mt: 4,
      }}
    >
      <form ref={form} onSubmit={sendEmail} id="form">
        {isLargeScreen && (
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Typography
                  align="center"
                  sx={{
                    fontFamily: "Inria Sans",
                    fontWeight: 700,
                    color: "#154F75",
                    mt: 2,
                    mb: 8,
                  }}
                  variant="h2"
                >
                  Reach Us !
                </Typography>
                <Box
                  component={"img"}
                  src={reachUsBanner}
                  sx={{
                    width: "25vw",
                  }}
                />
              </Box>
            </Grid>
            <Grid item xs={7}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                <FormControl
                  sx={{
                    fontFamily: "Poppins",
                    fontWeight: 400,
                    width: "100%",
                  }}
                >
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "flex-end",
                    }}
                  >
                    <Input
                      placeholder="Name"
                      sx={{
                        background: "#F7F5FE",
                        border: "0",
                        borderRadius: "0.25rem",
                        px: 2,
                        py: 1,
                        fontFamily: "Poppins",
                        fontWeight: 400,
                        fontSize: "1rem",
                        width: "60%",
                        my: "0.5rem",
                      }}
                      name="name"
                      required
                    />
                    <Input
                      placeholder="Location"
                      sx={{
                        background: "#F7F5FE",
                        border: "0",
                        borderRadius: "0.25rem",
                        px: 2,
                        py: 1,
                        fontFamily: "Poppins",
                        fontWeight: 400,
                        fontSize: "1rem",
                        width: "60%",
                        my: "0.5rem",
                      }}
                      name="location"
                      required
                    />
                    <Input
                      placeholder="Email Id"
                      type="email"
                      sx={{
                        background: "#F7F5FE",
                        border: "0",
                        borderRadius: "0.25rem",
                        px: 2,
                        py: 1,
                        fontFamily: "Poppins",
                        fontWeight: 400,
                        fontSize: "1rem",
                        width: "60%",
                        my: "0.5rem",
                      }}
                      name="email"
                      required
                    />
                    <Input
                      placeholder="Phone No."
                      sx={{
                        background: "#F7F5FE",
                        border: "0",
                        borderRadius: "0.25rem",
                        px: 2,
                        py: 1,
                        fontFamily: "Poppins",
                        fontWeight: 400,
                        fontSize: "1rem",
                        width: "60%",
                        my: "0.5rem",
                      }}
                      type="tel"
                      name="phone"
                      required
                    />
                    <Input
                      placeholder="No. of Years of Experience"
                      sx={{
                        background: "#F7F5FE",
                        border: "0",
                        borderRadius: "0.25rem",
                        px: 2,
                        py: 1,
                        fontFamily: "Poppins",
                        fontWeight: 400,
                        fontSize: "1rem",
                        width: "60%",
                        my: "0.5rem",
                      }}
                      type="number"
                      name="year"
                      required
                    />
                    <Input
                      placeholder="Latest Qualification"
                      sx={{
                        background: "#F7F5FE",
                        border: "0",
                        borderRadius: "0.25rem",
                        px: 2,
                        py: 1,
                        fontFamily: "Poppins",
                        fontWeight: 400,
                        fontSize: "1rem",
                        width: "60%",
                        my: "0.5rem",
                      }}
                      type="text"
                      name="qualification"
                      required
                    />
                    <textarea
                      style={{
                        width: "60%",
                        height: "6rem",
                        background: "#F7F5FE",
                        border: "none",
                        borderRadius: "0.25rem",
                        marginTop: "0.5rem",
                        marginBottom: "0.5rem",
                        fontFamily: "Poppins",
                        fontWeight: 400,
                        fontSize: "1rem",
                      }}
                      placeholder="Why you want to join worthy advisors"
                      name="message"
                      required
                    ></textarea>
                    <input type="file"
                      id="fileInput"
                      style={{
                        visibility: "hidden"
                      }}
                    ></input>
                    <Box
                      sx={{
                        width: "60%",
                        py: 6,
                        background: "#F7F5FE",
                        border: "0.1rem dashed #A7A7A7",
                        borderRadius: "0.25rem",
                        marginTop: "0.5rem",
                        marginBottom: "0.5rem",
                        fontFamily: "Poppins",
                        fontWeight: 400,
                        fontSize: "1rem",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                      onClick={handleUploadFile}
                    >
                      <Box component={"img"} src={uploadIcon} />
                      <Box
                        sx={{
                          color: "#2C3345",
                        }}
                      >
                        Upload Resume
                      </Box>
                      <Box
                        sx={{
                          color: "#57647E",
                        }}
                      >
                        Drag and Drop Files here
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: "60%",
                      }}
                    >
                      <Button
                        sx={{
                          background: "#154f75",
                          borderRadius: "0.4rem",
                          py: "0.75rem",
                          px: "2rem",
                        }}
                        type="submit"
                        value="Send"
                        id="button"
                      >
                        <Link
                          style={{
                            textDecoration: "none",
                          }}
                        >
                          <Typography
                            sx={{
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              color: "#FFFFFF",
                            }}
                          >
                            Apply Now
                          </Typography>
                        </Link>
                      </Button>
                      <Box component="img" src={careerCompanyLogo} />
                    </Box>
                  </Box>
                </FormControl>
              </Box>
            </Grid>
          </Grid>
        )}
        {isSmallScreen && (
          <Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Typography
                align="center"
                sx={{
                  fontFamily: "Inria Sans",
                  fontWeight: 700,
                  color: "#154F75",
                  mt: 2,
                  mb: 8,
                }}
                variant="h2"
              >
                Reach Us !
              </Typography>
              <Box
                component={"img"}
                src={reachUsBanner}
                sx={{
                  width: "40vw",
                }}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
                mt: 2,
              }}
            >
              <FormControl
                sx={{
                  fontFamily: "Poppins",
                  fontWeight: 400,
                  width: "100%",
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Input
                    placeholder="Name"
                    sx={{
                      background: "#F7F5FE",
                      border: "0",
                      borderRadius: "0.25rem",
                      px: 2,
                      py: 1,
                      fontFamily: "Poppins",
                      fontWeight: 400,
                      fontSize: "1rem",
                      width: "80%",
                      my: "0.5rem",
                    }}
                    name="name"
                    required
                  />
                  <Input
                    placeholder="Location"
                    sx={{
                      background: "#F7F5FE",
                      border: "0",
                      borderRadius: "0.25rem",
                      px: 2,
                      py: 1,
                      fontFamily: "Poppins",
                      fontWeight: 400,
                      fontSize: "1rem",
                      width: "80%",
                      my: "0.5rem",
                    }}
                    name="location"
                    required
                  />
                  <Input
                    placeholder="Email Id"
                    type="email"
                    sx={{
                      background: "#F7F5FE",
                      border: "0",
                      borderRadius: "0.25rem",
                      px: 2,
                      py: 1,
                      fontFamily: "Poppins",
                      fontWeight: 400,
                      fontSize: "1rem",
                      width: "80%",
                      my: "0.5rem",
                    }}
                    name="email"
                    required
                  />
                  <Input
                    placeholder="Phone No."
                    sx={{
                      background: "#F7F5FE",
                      border: "0",
                      borderRadius: "0.25rem",
                      px: 2,
                      py: 1,
                      fontFamily: "Poppins",
                      fontWeight: 400,
                      fontSize: "1rem",
                      width: "80%",
                      my: "0.5rem",
                    }}
                    type="tel"
                    name="phone"
                    required
                  />
                  <Input
                    placeholder="No. of Years of Experience"
                    sx={{
                      background: "#F7F5FE",
                      border: "0",
                      borderRadius: "0.25rem",
                      px: 2,
                      py: 1,
                      fontFamily: "Poppins",
                      fontWeight: 400,
                      fontSize: "1rem",
                      width: "80%",
                      my: "0.5rem",
                    }}
                    type="number"
                    name="year"
                    required
                  />
                  <Input
                    placeholder="Latest Qualification"
                    sx={{
                      background: "#F7F5FE",
                      border: "0",
                      borderRadius: "0.25rem",
                      px: 2,
                      py: 1,
                      fontFamily: "Poppins",
                      fontWeight: 400,
                      fontSize: "1rem",
                      width: "80%",
                      my: "0.5rem",
                    }}
                    type="text"
                    name="qualification"
                    required
                  />
                  <textarea
                    style={{
                      width: "80%",
                      height: "6rem",
                      background: "#F7F5FE",
                      border: "none",
                      borderRadius: "0.25rem",
                      marginTop: "0.5rem",
                      marginBottom: "0.5rem",
                      fontFamily: "Poppins",
                      fontWeight: 400,
                      fontSize: "1rem",
                    }}
                    placeholder="Why you want to join worthy advisors"
                    name="message"
                    required
                  ></textarea>
                  <Box
                    sx={{
                      width: "80%",
                      py: 6,
                      background: "#F7F5FE",
                      border: "0.1rem dashed #A7A7A7",
                      borderRadius: "0.25rem",
                      marginTop: "0.5rem",
                      marginBottom: "0.5rem",
                      fontFamily: "Poppins",
                      fontWeight: 400,
                      fontSize: "1rem",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Box component={"img"} src={uploadIcon} />
                    <Box
                      sx={{
                        color: "#2C3345",
                      }}
                    >
                      Upload Resume
                    </Box>
                    <Box
                      sx={{
                        color: "#57647E",
                      }}
                    >
                      Drag and Drop Files here
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: "80%",
                    }}
                  >
                    <Button
                      sx={{
                        background: "#154F75",
                        borderRadius: "0.4rem",
                        py: "0.75rem",
                        px: "2rem",
                      }}
                      type="submit"
                      value="Send"
                      id="button"
                    >
                      <Link
                        style={{
                          textDecoration: "none",
                        }}
                      >
                        <Typography
                          sx={{
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            color: "#FFFFFF",
                          }}
                        >
                          Apply Now
                        </Typography>
                      </Link>
                    </Button>
                    <Box component="img" src={careerCompanyLogo} />
                  </Box>
                </Box>
              </FormControl>
            </Box>
          </Box>
        )}
      </form>
    </Box>
  );
};
