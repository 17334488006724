import {
  Box,
  Paper,
  Typography,
  useMediaQuery,
  Button,
  Divider,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { Link } from "@mui/material";
import redirectLogo from "../../resources/blogs/redirect-logo.svg";
import treasuryBlogBanner from "../../resources/home/treasury-blog-banner.svg";
import cashFlowBlogBanner from "../../resources/home/cash-flow-blog-banner.svg";
import interestRateBlogBanner from "../../resources/home/interest-rate-blog-banner.svg";

const BlogCard = (props) => {
  return (
    <Paper
      elevation={2}
      sx={{
        p: 2,
        my: 2,
      }}
    >
      <Paper
        elevation={2}
        sx={{
          px: 2,
          mb: 2,
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Box
          component={"img"}
          src={props.image}
          sx={{ height: "250px", width: "250px" }}
        />
      </Paper>
      <Typography
        align="center"
        sx={{
          fontFamily: "Roboto",
          fontSize: "1rem",
          fontWeight: 100,
          py: 1,
        }}
      >
        Worthy Advisors
      </Typography>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Typography
          align="center"
          sx={{
            fontFamily: "Roboto",
            fontSize: "1rem",
            fontWeight: 500,
            py: 1,
            width: "80%",
          }}
        >
          {props.heading}
        </Typography>
      </Box>
      <Divider variant="middle" />
      <Link
        href="/"
        sx={{
          textDecoration: "none",
        }}
      >
        <Typography
          align="center"
          sx={{
            fontFamily: "Roboto",
            color: "#074F63",
            fontWeight: 500,
            pt: 2,
          }}
        >
          <Link href={props.link}>
            <Button
              sx={{
                fontFamily: "Roboto",
                color: "#074F63",
                fontWeight: 500,
                pt: 1,
              }}
            >
              Read More
            </Button>
          </Link>
        </Typography>
      </Link>
    </Paper>
  );
};

export const BlogSection = () => {
  const isLargeScreen = useMediaQuery("(min-width: 850px)");
  const isSmallScreen = useMediaQuery(
    "(max-width: 850px) and (min-width: 500px)"
  );
  const isSmallerScreen = useMediaQuery("(max-width: 500px)");

  const [posts, setPosts] = useState([]);
  useEffect(() => {
    fetch("https://worthyadvisors.onrender.com/post").then((response) => {
      response.json().then((posts) => {
        setPosts(posts);
      });
    });
  }, []);

  const contentArray = [
    {
      img: treasuryBlogBanner,
      heading: "Is Treasury Still Underrated In Your Company",
      link: "https://www.linkedin.com/feed/update/urn:li:activity:7080419505243394048?utm_source=share&utm_medium=member_android",
    },
    {
      img: cashFlowBlogBanner,
      heading: "Is Treasury Still Underrated In Your Company",
      link: "https://www.linkedin.com/feed/update/urn:li:activity:7083363258790776832/",
    },
    {
      img: interestRateBlogBanner,
      heading: "Is Treasury Still Underrated In Your Company",
      link: "https://www.linkedin.com/posts/worthy-advisors_interest-rates-activity-7089484832329666561-QMc1?utm_source=share&utm_medium=member_android",
    },
  ];

  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
        justifyContent: () => {
          if (isSmallerScreen) {
            return "center";
          } else {
            return "space-evenly";
          }
        },
        alignItems: "center",
        py: 8,
      }}
    >
      {posts.length > 0 &&
        posts.map((post) => {
          return (
            <BlogCard
              image={post.cover}
              heading={post.title}
              link={"/blog/" + post.searchtitle}
            />
          );
        })}
    </Box>
  );
};
