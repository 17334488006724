const serviceConsts = {
  content: [
    {
      head: "We manage your cash flow and your corporate investments",
      body: [
        {
          head: "Understanding Money Flow & Working Capital Cycle:",
          body: "We dive deep into your business's financial's to understand the money flow and the working capital cycle, enabling effective cash flow management.",
        },
        {
          head: "Daily Cash Movement Report:",
          body: "We prepare a daily cash movement report that provides clear visibility to the management on daily inflow and outflow, supporting informed decision making.",
        },
        {
          head: "Fund Planning:",
          body: "Our team helps with fund planning for both short-term and long-term requirements, ensuring optimal allocation of resources.",
        },
        {
          head: "Bucketing of Funds:",
          body: "We bucket funds according to your company's requirements and chalk out the horizon of the investments, providing a clear roadmap for financial growth.",
        },
        {
          head: "Investment Policy Statement:",
          body: "We craft and adhere to an Investment Policy Statement that prioritizes safety, liquidity, and returns. We also uncover investment opportunities tailored to the company's needs, amplifying the potential for financial growth.",
        },
        {
          head: "Continuous Monitoring:",
          body: "We continuously monitor the investments and churn them according to market conditions or available opportunities, ensuring your funds are always working for you.",
        },
      ],
    },
    {
      head: "We manage your Commercial Insurance",
      body: [
        {
          head: "Risk Assessment:",
          body: "We assess potential risks across all areas of your business, including employee health, inventory, fixed assets, money, fidelity, marine, directors & officers, cyber crime, and more.",
        },
        {
          head: "Insurance Portfolio Management:",
          body: "We manage your entire insurance portfolio, ensuring that you have the right coverage in place to protect your business. This includes policies such as employee health insurance, inventory insurance, fixed asset insurance, money insurance, fidelity insurance, marine insurance, directors & officers insurance, and cyber insurance.",
        },
        {
          head: "Claim Management:",
          body: "We work closely with you to ensure that the claim process is smooth and efficient, from intimation to settlement. Our team is dedicated to helping you navigate the complexities of the insurance industry, ensuring that you receive the maximum benefit possible.",
        },
        {
          head: "Process Implementation:",
          body: "We implement processes and procedures to ensure that your insurance portfolio is properly managed, and that you are well-prepared to handle any potential risks that may arise.",
        },
      ],
    },
    {
      head: "We manage your Fund Raising and Borrowings",
      body: [
        {
          head: "Strategic Capital Utilization:",
          body: "Make the most of equity capital by optimizing its deployment, ensuring efficiency in business operations.",
        },
        {
          head: "Tailored Funding Solutions:",
          body: "Whether it's for working capital or capital expenditure, we provide customized fund planning to meet short-term and long-term requirements.",
        },
        {
          head: "Equity Investment Arrangement:",
          body: "We help secure equity investments that align with your company's vision, enabling growth and expansion opportunities.",
        },
        {
          head: "Covenant Evaluation and Negotiation:",
          body: "We meticulously assess and negotiate covenants to secure reasonable terms, ensuring your company's interests are well-protected.",
        },
        {
          head: "Enhanced Supply Chain Financing:",
          body: "Empower stakeholders by offering innovative solutions like BNPL for customers and trade financing for suppliers, fostering strong relationships and operational resilience",
        },
        {
          head: "Efficient Debt Management:",
          body: "Ensure on-time debt servicing. Enhance financial credibility.",
        },
        {
          head: "Innovative Financing:",
          body: "Revamp supply chain with BNPL, invoice discounting, and trade financing.",
        },
      ],
    },
    {
      head: "We manage your Banking, Budgeting, and CMS Solutions",
      body: [
        {
          head: "Strategic Banking Relationships:",
          body: "Selecting the right banking partner is paramount. We analyze your company's needs to ensure a perfect fit, managing overall banking relationships for seamless financial operations.",
        },
        {
          head: "Holistic Loan Facility Management:",
          body: "Our experts liaise with banks to arrange various loan facilities, transaction banking, FDI, and more. We streamline the process, ensuring you have the financial tools you need to thrive.",
        },
        {
          head: "Cutting-edge Payment Automation:",
          body: "Experience the future of financial management with our API banking, H2H integration, virtual accounts, and payment gateways. Automate payments and collections effortlessly for streamlined operations.",
        },
        {
          head: "Optimized Banking Services:",
          body: "We evaluate a range of banking products and services, optimizing your company's operations. Uncover innovative solutions that enhance efficiency and contribute to your financial success.",
        },
        {
          head: "Result-driven Budgeting Culture:",
          body: "We kickstart your financial month with effective budgeting practices that track actuals throughout the month. Empower your financial decisions with accurate insights and strategic planning.",
        },
      ],
    },

    {
      head: "Forex , Financial Decision Making and MIS",
      body: [
        {
          head: "Foreign Remittance:",
          body: "We facilitate fast and secure cross-border payments with competitive exchange rates and low fees.",
        },
        {
          head: "Hedging:",
          body: "We design and implement effective hedging strategies to protect our clients from adverse currency movements and reduce volatility in their cash flows.",
        },
        {
          head: "Leasing:",
          body: "We help you making decision whether to Buy or Lease Fixed Assets.",
        },
        {
          head: "Data-Driven Investment Insights:",
          body: "Stay ahead with insights. Our fortnightly investment position reports provide a clear overview of returns on investment, aiding in strategic financial planning.",
        },
        {
          head: "Strategic Treasury Dashboards:",
          body: "Our experts develop comprehensive dashboards and management information systems (MIS) that provide real-time reporting of all treasury activities. Keep stakeholders informed and make data-driven decisions.",
        },
      ],
    },
  ],
};

export default serviceConsts;
