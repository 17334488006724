import { FormControl } from "@mui/base";
import {
  Box,
  InputLabel,
  Typography,
  Input,
  Grid,
  useMediaQuery,
  Button,
} from "@mui/material";
import { Link } from "@mui/material";
import React, { useRef } from "react";
import emailjs from "@emailjs/browser";

export const ContactForm = () => {
  const isBigScreen = useMediaQuery("(min-width: 1200px)");
  const isSmallScreen = useMediaQuery(
    "(max-width: 1200px) and (min-width: 600px)"
  );
  const isSmallerScreen = useMediaQuery("(max-width: 600px)");
  const form = useRef();

  //   const btn = document.getElementById("button");

  const sendEmail = (e) => {
    e.preventDefault();
    // btn.value = "Sending...";
    emailjs
      .sendForm(
        "service_5tf4w5k",
        "template_6cufxc2",
        form.current,
        "KXHDeY1Z2zqrT9dR5"
      )
      .then(
        (result) => {
          console.log(result.text);
          console.log("message sent");
          //   btn.value = "SUBMIT";
          alert("Submitted!");
          //   btn.value = "SUBMIT";
          e.target.reset();
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
    <Box
      sx={{
        py: 4,
      }}
    >
      <form ref={form} onSubmit={sendEmail} id="form">
        <Typography
          sx={{
            fontFamily: "Poppins",
            fontWeight: 700,
            mb: 4,
            color: "#FFFFFF",
          }}
          variant="h4"
        >
          Get in touch today and let one of our experts contact you
        </Typography>
        {isBigScreen && (
          <FormControl>
            <Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  my: 2,
                }}
              >
                <Box
                  sx={{
                    width: "50%",
                    py: 1,
                  }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={3}>
                      <InputLabel
                        sx={{
                          fontFamily: "Poppins",
                          fontWeight: 400,
                          pr: 4,
                          color: "#FFFFFF",
                        }}
                      >
                        Name
                      </InputLabel>
                    </Grid>
                    <Grid xs={9}>
                      <Input
                        sx={{
                          background: "rgba(238, 238, 238, 0.51)",
                          backdropFilter: "blur(0.2rem)",
                          fontFamily: "Poppins",
                          fontWeight: 400,
                          width: "90%",
                          borderRadius: "0.25rem",
                          px: 2,
                          py: 1,
                          color: "#FFFFFF",
                        }}
                        type="text"
                        name="f_name"
                        required
                      />
                    </Grid>
                  </Grid>
                </Box>
                <Box
                  sx={{
                    width: "50%",
                    py: 1,
                  }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={3}>
                      <InputLabel
                        sx={{
                          fontFamily: "Poppins",
                          fontWeight: 400,
                          pr: 4,
                          color: "#FFFFFF",
                        }}
                      >
                        Location
                      </InputLabel>
                    </Grid>
                    <Grid xs={9}>
                      <Input
                        sx={{
                          background: "rgba(238, 238, 238, 0.51)",
                          backdropFilter: "blur(0.2rem)",
                          fontFamily: "Poppins",
                          fontWeight: 400,
                          width: "90%",
                          borderRadius: "0.25rem",
                          px: 2,
                          py: 1,
                          color: "#FFFFFF",
                        }}
                        type="text"
                        name="l_name"
                        required
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  mb: 2,
                }}
              >
                <Box
                  sx={{
                    width: "50%",
                    py: 1,
                  }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={3}>
                      <InputLabel
                        sx={{
                          fontFamily: "Poppins",
                          fontWeight: 400,
                          pr: 4,
                          color: "#FFFFFF",
                        }}
                      >
                        Email
                      </InputLabel>
                    </Grid>
                    <Grid xs={9}>
                      <Input
                        sx={{
                          background: "rgba(238, 238, 238, 0.51)",
                          backdropFilter: "blur(0.2rem)",
                          fontFamily: "Poppins",
                          fontWeight: 400,
                          width: "90%",
                          borderRadius: "0.25rem",
                          px: 2,
                          py: 1,
                          color: "#FFFFFF",
                        }}
                        type="email"
                        name="email"
                        required
                      />
                    </Grid>
                  </Grid>
                </Box>
                <Box
                  sx={{
                    width: "50%",
                    py: 1,
                  }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={3}>
                      <InputLabel
                        sx={{
                          fontFamily: "Poppins",
                          fontWeight: 400,
                          pr: 4,
                          color: "#FFFFFF",
                        }}
                      >
                        Phone
                      </InputLabel>
                    </Grid>
                    <Grid xs={9}>
                      <Input
                        sx={{
                          background: "rgba(238, 238, 238, 0.51)",
                          backdropFilter: "blur(0.2rem)",
                          fontFamily: "Poppins",
                          fontWeight: 400,
                          width: "90%",
                          borderRadius: "0.25rem",
                          px: 2,
                          py: 1,
                          color: "#FFFFFF",
                        }}
                        type="tel"
                        name="phone"
                        required
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  mb: 2,
                }}
              >
                <Box
                  sx={{
                    width: "50%",
                    py: 1,
                  }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={3}>
                      <InputLabel
                        sx={{
                          fontFamily: "Poppins",
                          fontWeight: 400,
                          pr: 4,
                          color: "#FFFFFF",
                        }}
                      >
                        Company
                      </InputLabel>
                    </Grid>
                    <Grid xs={9}>
                      <Input
                        sx={{
                          background: "rgba(238, 238, 238, 0.51)",
                          backdropFilter: "blur(0.2rem)",
                          fontFamily: "Poppins",
                          fontWeight: 400,
                          width: "90%",
                          borderRadius: "0.25rem",
                          px: 2,
                          py: 1,
                          color: "#FFFFFF",
                        }}
                        type="text"
                        name="company"
                        required
                      />
                    </Grid>
                  </Grid>
                </Box>
                <Box
                  sx={{
                    width: "50%",
                    py: 1,
                  }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={3}>
                      <InputLabel
                        sx={{
                          fontFamily: "Poppins",
                          fontWeight: 400,
                          pr: 4,
                          color: "#FFFFFF",
                        }}
                      >
                        Designation
                      </InputLabel>
                    </Grid>
                    <Grid xs={9}>
                      <Input
                        sx={{
                          background: "rgba(238, 238, 238, 0.51)",
                          backdropFilter: "blur(0.2rem)",
                          fontFamily: "Poppins",
                          fontWeight: 400,
                          width: "90%",
                          borderRadius: "0.25rem",
                          px: 2,
                          py: 1,
                          color: "#FFFFFF",
                        }}
                        type="text"
                        name="designation"
                        required
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Box>
            <Grid
              container
              spacing={0}
              sx={{
                display: "flex",
                width: "100%",
              }}
            >
              <Grid item xs={1.4}>
                <InputLabel
                  sx={{
                    fontFamily: "Poppins",
                    fontWeight: 400,
                    color: "#FFFFFF",
                  }}
                >
                  Talk to us
                </InputLabel>
              </Grid>
              <Grid item xs={10.6}>
                <textarea
                  style={{
                    background: "rgba(238, 238, 238, 0.51)",
                    backdropFilter: "blur(0.2rem)",
                    fontFamily: "Poppins",
                    fontWeight: 400,
                    width: "95%",
                    height: "6rem",
                    borderRadius: "0.25rem",
                    padding: "1rem 1rem",
                    color: "#FFFFFF",
                  }}
                  type="text"
                  placeholder="Describe you requirement in Detail"
                  name="message"
                  required
                ></textarea>
              </Grid>
            </Grid>
          </FormControl>
        )}
        {isSmallScreen && (
          <FormControl>
            <Box
              sx={{
                py: 1,
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={3}>
                  <InputLabel
                    sx={{
                      fontFamily: "Poppins",
                      fontWeight: 400,
                      pr: 4,
                      color: "#FFFFFF",
                    }}
                  >
                    Name
                  </InputLabel>
                </Grid>
                <Grid xs={9}>
                  <Input
                    sx={{
                      background: "rgba(238, 238, 238, 0.51)",
                      backdropFilter: "blur(0.2rem)",
                      fontFamily: "Poppins",
                      fontWeight: 400,
                      width: "90%",
                      borderRadius: "0.25rem",
                      px: 2,
                      py: 1,
                      color: "#FFFFFF",
                    }}
                    type="text"
                    name="f_name"
                    required
                  />
                </Grid>
              </Grid>
            </Box>
            <Box
              sx={{
                py: 1,
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={3}>
                  <InputLabel
                    sx={{
                      fontFamily: "Poppins",
                      fontWeight: 400,
                      pr: 4,
                      color: "#FFFFFF",
                    }}
                  >
                    Location
                  </InputLabel>
                </Grid>
                <Grid xs={9}>
                  <Input
                    sx={{
                      background: "rgba(238, 238, 238, 0.51)",
                      backdropFilter: "blur(0.2rem)",
                      fontFamily: "Poppins",
                      fontWeight: 400,
                      width: "90%",
                      borderRadius: "0.25rem",
                      px: 2,
                      py: 1,
                      color: "#FFFFFF",
                    }}
                    type="text"
                    name="l_name"
                    required
                  />
                </Grid>
              </Grid>
            </Box>
            <Box
              sx={{
                py: 1,
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={3}>
                  <InputLabel
                    sx={{
                      fontFamily: "Poppins",
                      fontWeight: 400,
                      pr: 4,
                      color: "#FFFFFF",
                    }}
                  >
                    Email
                  </InputLabel>
                </Grid>
                <Grid xs={9}>
                  <Input
                    sx={{
                      background: "rgba(238, 238, 238, 0.51)",
                      backdropFilter: "blur(0.2rem)",
                      fontFamily: "Poppins",
                      fontWeight: 400,
                      width: "90%",
                      borderRadius: "0.25rem",
                      px: 2,
                      py: 1,
                      color: "#FFFFFF",
                    }}
                    type="email"
                    name="email"
                    required
                  />
                </Grid>
              </Grid>
            </Box>
            <Box
              sx={{
                py: 1,
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={3}>
                  <InputLabel
                    sx={{
                      fontFamily: "Poppins",
                      fontWeight: 400,
                      pr: 4,
                      color: "#FFFFFF",
                    }}
                  >
                    Phone
                  </InputLabel>
                </Grid>
                <Grid xs={9}>
                  <Input
                    sx={{
                      background: "rgba(238, 238, 238, 0.51)",
                      backdropFilter: "blur(0.2rem)",
                      fontFamily: "Poppins",
                      fontWeight: 400,
                      width: "90%",
                      borderRadius: "0.25rem",
                      px: 2,
                      py: 1,
                      color: "#FFFFFF",
                    }}
                    type="tel"
                    name="phone"
                    required
                  />
                </Grid>
              </Grid>
            </Box>
            <Box
              sx={{
                py: 1,
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={3}>
                  <InputLabel
                    sx={{
                      fontFamily: "Poppins",
                      fontWeight: 400,
                      pr: 4,
                      color: "#FFFFFF",
                    }}
                  >
                    Company
                  </InputLabel>
                </Grid>
                <Grid xs={9}>
                  <Input
                    sx={{
                      background: "rgba(238, 238, 238, 0.51)",
                      backdropFilter: "blur(0.2rem)",
                      fontFamily: "Poppins",
                      fontWeight: 400,
                      width: "90%",
                      borderRadius: "0.25rem",
                      px: 2,
                      py: 1,
                      color: "#FFFFFF",
                    }}
                    type="text"
                    name="company"
                    required
                  />
                </Grid>
              </Grid>
            </Box>
            <Box
              sx={{
                py: 1,
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={3}>
                  <InputLabel
                    sx={{
                      fontFamily: "Poppins",
                      fontWeight: 400,
                      pr: 4,
                      color: "#FFFFFF",
                    }}
                  >
                    Designation
                  </InputLabel>
                </Grid>
                <Grid xs={9}>
                  <Input
                    sx={{
                      background: "rgba(238, 238, 238, 0.51)",
                      backdropFilter: "blur(0.2rem)",
                      fontFamily: "Poppins",
                      fontWeight: 400,
                      width: "90%",
                      borderRadius: "0.25rem",
                      px: 2,
                      py: 1,
                      color: "#FFFFFF",
                    }}
                    type="text"
                    name="designation"
                    required
                  />
                </Grid>
              </Grid>
            </Box>
            <Grid
              container
              spacing={0}
              sx={{
                display: "flex",
                width: "100%",
              }}
            >
              <Grid item xs={2.8}>
                <InputLabel
                  sx={{
                    fontFamily: "Poppins",
                    fontWeight: 400,
                    color: "#FFFFFF",
                  }}
                >
                  Talk to us
                </InputLabel>
              </Grid>
              <Grid item xs={9.2}>
                <textarea
                  style={{
                    background: "rgba(238, 238, 238, 0.51)",
                    backdropFilter: "blur(0.2rem)",
                    fontFamily: "Poppins",
                    fontWeight: 400,
                    width: "80%",
                    height: "6rem",
                    borderRadius: "0.25rem",
                    padding: "1rem 1rem",
                    color: "#FFFFFF",
                  }}
                  type="text"
                  placeholder="Describe you requirement in Detail"
                  name="message"
                  required
                ></textarea>
              </Grid>
            </Grid>
          </FormControl>
        )}
        {isSmallerScreen && (
          <FormControl>
            <InputLabel
              sx={{
                fontFamily: "Poppins",
                fontWeight: 400,
                pr: 4,
                color: "#FFFFFF",
              }}
            >
              First Name
            </InputLabel>
            <Input
              sx={{
                background: "rgba(238, 238, 238, 0.51)",
                backdropFilter: "blur(0.2rem)",
                fontFamily: "Poppins",
                fontWeight: 400,
                width: "90%",
                borderRadius: "0.25rem",
                px: 2,
                py: 1,
                color: "#FFFFFF",
              }}
              type="text"
              name="f_name"
              required
            />
            <InputLabel
              sx={{
                fontFamily: "Poppins",
                fontWeight: 400,
                pr: 4,
                color: "#FFFFFF",
              }}
            >
              Last Name
            </InputLabel>
            <Input
              sx={{
                background: "rgba(238, 238, 238, 0.51)",
                backdropFilter: "blur(0.2rem)",
                fontFamily: "Poppins",
                fontWeight: 400,
                width: "90%",
                borderRadius: "0.25rem",
                px: 2,
                py: 1,
                color: "#FFFFFF",
              }}
              type="text"
              name="l_name"
              required
            />
            <InputLabel
              sx={{
                fontFamily: "Poppins",
                fontWeight: 400,
                pr: 4,
                color: "#FFFFFF",
              }}
            >
              Email
            </InputLabel>
            <Input
              sx={{
                background: "rgba(238, 238, 238, 0.51)",
                backdropFilter: "blur(0.2rem)",
                fontFamily: "Poppins",
                fontWeight: 400,
                width: "90%",
                borderRadius: "0.25rem",
                px: 2,
                py: 1,
                color: "#FFFFFF",
              }}
              type="email"
              name="email"
              required
            />
            <InputLabel
              sx={{
                fontFamily: "Poppins",
                fontWeight: 400,
                pr: 4,
                color: "#FFFFFF",
              }}
            >
              Phone
            </InputLabel>
            <Input
              sx={{
                background: "rgba(238, 238, 238, 0.51)",
                backdropFilter: "blur(0.2rem)",
                fontFamily: "Poppins",
                fontWeight: 400,
                width: "90%",
                borderRadius: "0.25rem",
                px: 2,
                py: 1,
                color: "#FFFFFF",
              }}
              type="tel"
              name="phone"
              required
            />
            <InputLabel
              sx={{
                fontFamily: "Poppins",
                fontWeight: 400,
                pr: 4,
                color: "#FFFFFF",
              }}
            >
              Company
            </InputLabel>
            <Input
              sx={{
                background: "rgba(238, 238, 238, 0.51)",
                backdropFilter: "blur(0.2rem)",
                fontFamily: "Poppins",
                fontWeight: 400,
                width: "90%",
                borderRadius: "0.25rem",
                px: 2,
                py: 1,
                color: "#FFFFFF",
              }}
              type="text"
              name="company"
              required
            />
            <InputLabel
              sx={{
                fontFamily: "Poppins",
                fontWeight: 400,
                pr: 4,
                color: "#FFFFFF",
              }}
            >
              Designation
            </InputLabel>
            <Input
              sx={{
                background: "rgba(238, 238, 238, 0.51)",
                backdropFilter: "blur(0.2rem)",
                fontFamily: "Poppins",
                fontWeight: 400,
                width: "90%",
                borderRadius: "0.25rem",
                px: 2,
                py: 1,
                color: "#FFFFFF",
              }}
              type="text"
              name="designation"
              required
            />
            <InputLabel
              sx={{
                fontFamily: "Poppins",
                fontWeight: 400,
                color: "#FFFFFF",
              }}
            >
              Talk with us
            </InputLabel>
            <textarea
              style={{
                background: "rgba(238, 238, 238, 0.51)",
                backdropFilter: "blur(0.2rem)",
                fontFamily: "Poppins",
                fontWeight: 400,
                width: "80%",
                height: "6rem",
                borderRadius: "0.25rem",
                padding: "1rem 1rem",
                color: "#FFFFFF",
              }}
              type="text"
              name="message"
              placeholder="Describe you requirement in Detail"
              required
            ></textarea>
          </FormControl>
        )}
        <Button
          sx={{
            background: "#154f75",
            backdropFilter: "blur(0.2rem)",
            borderRadius: "1.5rem",
            border: "none",
            px: 4,
            py: 1,
            mt: 2,
            ml: () => {
              if (isBigScreen) {
                return 16;
              } else {
                return 0;
              }
            },
          }}
          type="submit"
          value="Send"
          id="button"
        >
          <Link
            style={{
              textDecoration: "none",
            }}
          >
            <Typography
              sx={{
                fontFamily: "Poppins",
                fontWeight: 400,
                color: "#FFFFFF",
              }}
            >
              Submit
            </Typography>
          </Link>
        </Button>
      </form>
    </Box>
  );
};
