import React from "react";
import { Box, Container, Link, Typography, useMediaQuery } from "@mui/material";
import { NavBar } from "../components/navbar";
import { BlogSection } from "../components/blogs/blogSection";
import redirectLogo from "../resources/blogs/white-redirect-logo.svg";
import highlightBlogBanner from "../resources/blogs/highlight-blog-banner.svg";
import "../css/blogs.css";

export const Blogs = () => {
  const isBigScreen = useMediaQuery("(min-width: 900px)");
  const isSmallScreen = useMediaQuery("(max-width: 900px)");
  return (
    <Box>
      <Box className="blog-parent">
        <NavBar />
        <Box>
          <Container fixed>
            <Box
              sx={{
                py: 4,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: () => {
                  if (isBigScreen) {
                    return "row";
                  } else {
                    return "column";
                  }
                },
              }}
            >
              <Box
                component={"img"}
                src={highlightBlogBanner}
                sx={{
                  mr: 2,
                  width: () => {
                    if (isBigScreen) {
                      return "100%";
                    } else {
                      return "80vw";
                    }
                  },
                }}
              />
              <Box>
                <Typography
                  sx={{
                    fontFamily: "Poppins",
                    fontWeight: 700,
                    color: "#FFFFFF",
                    my: 2,
                  }}
                  variant="h4"
                >
                  Worthy Advisors believes in the power of the treasury function
                  to drive positive change for businesses of all sizes.
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "Poppins",
                    fontWeight: 400,
                    color: "#FFFFFF",
                    my: 2,
                  }}
                  variant="h5"
                >
                  We use our expertise to help clients generate more income,
                  reduce costs, and make the most of their funds.
                </Typography>
                <Link
                  sx={{
                    textDecoration: "none",
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "Poppins",
                      fontWeight: 400,
                      color: "#FFFFFF",
                      my: 2,
                    }}
                    variant="h6"
                  >
                    <Link
                      href="#blogs"
                      style={{
                        textDecoration: "none",
                        ml: 2,
                        color: "white",
                      }}
                    >
                      Read More
                    </Link>
                    <Box
                      component={"img"}
                      src={redirectLogo}
                      sx={{
                        ml: 2,
                        color: "white",
                      }}
                    />
                  </Typography>
                </Link>
              </Box>
            </Box>
          </Container>
        </Box>
      </Box>
      <Container id="blogs">
        <BlogSection />
      </Container>
    </Box>
  );
};
