import React from "react";
import { Box, Paper, Typography, Button, Divider, Link, } from "@mui/material";
import treasuryBlogBanner from '../../resources/home/treasury-blog-banner.svg'
import cashFlowBlogBanner from '../../resources/home/cash-flow-blog-banner.svg'
import interestRateBlogBanner from '../../resources/home/interest-rate-blog-banner.svg'

const BlogCard = (props) => {
    return (
        <Paper 
            elevation={2}
            sx={{
                p: 2,
                my: 2
            }}
        >
            <Paper
                elevation={2}
                sx={{
                    px: 2,
                    mb: 2,
                    display: "flex",
                    justifyContent: "center",
                }}
            >
                <Box 
                    component={"img"}
                    src={props.image}
                />
            </Paper>
            <Typography
                align="center"
                sx={{
                    fontFamily: "Roboto",
                    fontSize: "1rem",
                    fontWeight: 100,
                    py: 1,
                }}
            >
                Worthy Advisors
            </Typography>
            <Box
                sx={{
                    display: "flex",
                    justifyContent:"center",
                }}
            >
                <Typography
                    align="center"
                    sx={{
                        fontFamily: "Roboto",
                        fontSize: "1rem",
                        fontWeight: 500,
                        py: 1,
                        width: "80%",
                    }}
                >
                    {props.heading}
                </Typography>
            </Box>
            <Divider variant="middle"/>
            <Link
                href={props.link}
                sx={{
                    textDecoration: "none",
                }}
                >
                <Typography
                    align="center"
                    sx={{
                        fontFamily: "Roboto",
                        color : "#074F63",
                        fontWeight: 500,
                        pt: 2,
                    }}
                >
                    <Button
                        sx={{
                            fontFamily: "Roboto",
                            color : "#074F63",
                            fontWeight: 500,
                            pt: 1,
                        }}
                    >
                        Read More
                    </Button>
                </Typography>
            </Link>
        </Paper>
    )
}

export const BlogSection = () => {

    const contentArray = [
        {
            img: treasuryBlogBanner,
            heading: "Is Treasury Still Underrated In Your Company",
            link: "https://www.linkedin.com/feed/update/urn:li:activity:7080419505243394048?utm_source=share&utm_medium=member_android"
        },
        {
            img: cashFlowBlogBanner,
            heading: "Is Treasury Still Underrated In Your Company",
            link: "https://www.linkedin.com/feed/update/urn:li:activity:7083363258790776832/"
        },
        {
            img: interestRateBlogBanner,
            heading: "Is Treasury Still Underrated In Your Company",
            link: "https://www.linkedin.com/posts/worthy-advisors_interest-rates-activity-7089484832329666561-QMc1?utm_source=share&utm_medium=member_android"
        },
    ]

    return (
        <Box
            sx={{
                pt: 6,
                pb: 8,
            }}
        >
            <Typography
                align="center"
                variant="h4"
                sx={{
                    fontFamily: "Poppins",
                    fontWeight: 500,
                    pb: 2,
                }}
            >
                Our Most Read Posts
            </Typography>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-evenly",
                    flexWrap: "wrap",
                }}
            >
                {
                    contentArray.map((elem,index)=>{
                        return (
                            <BlogCard 
                                image={elem.img}
                                heading={elem.heading}
                                link={elem.link}
                            />
                        )
                    })
                }
            </Box>
        </Box>
    )
}