import React from "react";
import {
  Box,
  Container,
  Typography,
  Button,
  useMediaQuery,
  Link,
} from "@mui/material";
import { homeConsts } from "../resources/home/const";
import { HomePageNavBar } from "../components/home/home-page-navbar";
import { ServiceSection } from "../components/home/serviceSection";
import { WhyUsSection } from "../components/home/why-us-section";
import { BlogSection } from "../components/home/blogSection";
import { Banner } from "../components/home/banner";
import { ContactBanner } from "../components/home/contactBanner";
import "../css/home.css";

export const Home = () => {
  const isBiggerScreen = useMediaQuery("(min-width: 900px)");

  return (
    <Box>
      <Box className="parent">
        <HomePageNavBar />
        <Box className="parent1">
          <Box
            className="heading1"
            sx={{
              mt: () => {
                if (isBiggerScreen) {
                  return 16;
                } else {
                  return 8;
                }
              },
              ml: () => {
                if(isBiggerScreen){
                  return 8
                }else{
                  return 2
                }
              },
              pb: 20,
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                width: () => {
                  if (isBiggerScreen) {
                    return "50%";
                  } else {
                    return "80%";
                  }
                },
              }}
            >
              <Typography
                align={"left"}
                variant="h3"
                sx={{
                  fontFamily: "Inria Sans",
                  fontWeight: 700,
                  color: "#28292A",
                }}
              >
                Transforming Treasury,
                <br />
                Accelerating Growth
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                width: () => {
                  if (isBiggerScreen) {
                    return "40%";
                  } else {
                    return "80%";
                  }
                },
              }}
            >
              <Typography
                align="left"
                variant="h5"
                sx={{
                  fontFamily: "Inria Sans",
                  fontWeight: 400,
                  color: "#28292A",
                  mt: 2,
                }}
              >
                Your Trusted Treasury Consulting and <br />
                Outsourcing Partner. We Provide Expert
                <br /> Treasury Solutions for a Stronger Financial <br />
                Foundation of your Company
              </Typography>
            </Box>
            <Link
              href="/services"
              sx={{
                textDecoration: "none",
              }}
            >
              <Button
                sx={{
                  background: "#154F75",
                  borderRadius: "1rem",
                  px: 4,
                  py: 2,
                  mt: 4,
                  mb:2,
                  opacity: 1,
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Inter",
                    fontWeight: 700,
                    color: "#FFFFFF",
                  }}
                >
                  LEARN MORE
                </Typography>
              </Button>
            </Link>
          </Box>
        </Box>
      </Box>
      <Container>
        <Box
          className="heading2"
          sx={{
            mt: 16,
            mb: 4,
            pb: 8,
            background:
              "linear-gradient(180deg, rgba(0, 0, 0, 0.76) 0%, rgba(0, 0, 0, 0.57) 47.97%, rgba(0, 0, 0, 0.56) 71.35%, rgba(0, 0, 0, 0.50) 99.99%, rgba(0, 0, 0, 0.00) 100%)",
            borderRadius: "2rem",
            px: 6,
            py: 4,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              mb: 4,
            }}
          >
            <Typography
              align="center"
              variant="h4"
              sx={{
                fontFamily: "Montserrat",
                fontWeight: 700,
                color: "#FFFFFF",
              }}
            >
              {homeConsts.headings.heading2.head}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Typography
              align="center"
              variant="h6"
              sx={{
                fontFamily: "Poppins",
                fontWeight: 400,
                color: "#FFFFFF",
              }}
            >
              {homeConsts.headings.heading2.body}
            </Typography>
          </Box>
        </Box>
        <ServiceSection />
        <WhyUsSection />
      </Container>
      <Banner />
      <Container>
        <BlogSection />
      </Container>
      <ContactBanner />
    </Box>
  );
};
