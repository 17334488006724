import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import { Box, Grid, useMediaQuery } from "@mui/material";
import "../../css/navbar.css";
import homePageCompanyLogo from "../../resources/home/home-page-company-logo.svg";

export const HomePageNavBar = () => {
  const isSmallScreen = useMediaQuery("(max-width: 900px)");
  const isLargeScreen = useMediaQuery("(min-width: 900px)");
  const location = useLocation();
  console.log(location);
  return (
    <Box>
      {isLargeScreen && (
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Box>
              <Box className="logo" component="img" src={homePageCompanyLogo} />
            </Box>
          </Grid>
          <Grid item xs={6} display="flex" alignItems="center">
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-evenly",
                width: "100%",
              }}
            >
              <NavLink
                to="/home"
                className={({ isActive, isPending }) => {
                  if (isActive) {
                    return "active-link";
                  } else {
                    return "home-default-link";
                  }
                }}
              >
                Home
              </NavLink>
              <NavLink
                to="/services"
                className={({ isActive, isPending }) => {
                  if (isActive) {
                    return "active-link";
                  } else {
                    return "home-default-link";
                  }
                }}
              >
                Services
              </NavLink>
              <NavLink
                to="/blogs"
                className={({ isActive, isPending }) => {
                  if (isActive) {
                    return "active-link";
                  } else {
                    return "home-default-link";
                  }
                }}
              >
                Blogs
              </NavLink>
              <NavLink
                to="/careers"
                className={({ isActive, isPending }) => {
                  if (isActive) {
                    return "active-link";
                  } else {
                    return "home-default-link";
                  }
                }}
              >
                Careers
              </NavLink>
              <NavLink
                to="/contact"
                className={({ isActive, isPending }) => {
                  if (isActive) {
                    return "active-link";
                  } else {
                    return "home-default-link";
                  }
                }}
              >
                Contact
              </NavLink>
            </Box>
          </Grid>
        </Grid>
      )}
      {isSmallScreen && (
        <Box sx={{}}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              py: 4,
              px: 1,
            }}
          >
            <NavLink
              to="/home"
              className={({ isActive, isPending }) => {
                if (isActive) {
                  return "active-link";
                } else {
                  return "home-default-link";
                }
              }}
            >
              Home
            </NavLink>
            <NavLink
              to="/services"
              className={({ isActive, isPending }) => {
                if (isActive) {
                  return "active-link";
                } else {
                  return "home-default-link";
                }
              }}
            >
              Services
            </NavLink>
            <NavLink
              to="/blogs"
              className={({ isActive, isPending }) => {
                if (isActive) {
                  return "active-link";
                } else {
                  return "home-default-link";
                }
              }}
            >
              Blogs
            </NavLink>
            <NavLink
              to="/careers"
              className={({ isActive, isPending }) => {
                if (isActive) {
                  return "active-link";
                } else {
                  return "home-default-link";
                }
              }}
            >
              Careers
            </NavLink>
            <NavLink
              to="/contact"
              className={({ isActive, isPending }) => {
                if (isActive) {
                  return "active-link";
                } else {
                  return "home-default-link";
                }
              }}
            >
              Contact
            </NavLink>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Box component="img" src={homePageCompanyLogo} />
          </Box>
        </Box>
      )}
    </Box>
  );
};
