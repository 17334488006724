import React from "react";
import {
  Box,
  Container,
  Typography,
  useMediaQuery,
  Paper,
} from "@mui/material";
import clientServiceLogo from "../../resources/home/client-service-logo.png";

export const Banner = () => {
  return (
    <Box className="banner">
      <Container>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            paddingTop: "70px",
            paddingBottom: "70px",
          }}
        >
          <Typography
            sx={{
              fontFamily: "Montserrat",
              fontWeight: 400,
              fontSize: "2.2rem",
              color: "#FFFFFF",
            }}
          >
            {/* Our */}
            <Box
              component="span"
              sx={{
                fontFamily: "Montserrat",
                fontWeight: 700,
              }}
            >
              {/* {"\n clients \n"} */}
            </Box>
            {/* <Box>love what we do</Box> */}
          </Typography>
          {/* <Box
            component="img"
            src={clientServiceLogo}
            sx={{
              width: "33vw",
            }}
          /> */}
        </Box>
      </Container>
    </Box>
  );
};
