import React from "react";

import { homeConsts } from "../../resources/home/const";
import whyUsImage from "../../resources/home/why-us-image.svg";
import { useMediaQuery, Box, Typography } from "@mui/material";

export const WhyUsSection = () => {
  const bigScreen = useMediaQuery("(min-width: 700px)");
  const smallScreen = useMediaQuery("(max-width: 700px)");
  return (
    <Box>
      {smallScreen && (
        <Box
          component="img"
          src={whyUsImage}
          sx={{
            width: "100%",
          }}
        />
      )}
      <Box
        sx={{
          display: "flex",
        }}
      >
        {bigScreen && (
          <Box
            component="img"
            src={whyUsImage}
            sx={{
              width: "30vw",
            }}
            style={{
              marginTop: "50px",
            }}
          />
        )}
        <Box
          sx={{
            width: "100%",
          }}
        >
          <Typography
            sx={{
              fontFamily: "Poppins",
              fontWeight: 500,
            }}
            variant="h4"
            align="left"
          >
            Why Us ?
          </Typography>
          <Box
            sx={{
              mt: 4,
            }}
          >
            {homeConsts.whyUsSection.map((elem, index) => {
              return (
                <Box
                  sx={{
                    width: "90%",
                    background: "rgba(228, 235, 255, 0.50)",
                    borderRadius: "2rem",
                    px: 2,
                    py: 1,
                    mb: 2,
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "Poppins",
                      fontSize: "1rem",
                    }}
                  >
                    {elem}
                  </Typography>
                </Box>
              );
            })}
          </Box>
        </Box>
        <Box></Box>
      </Box>
    </Box>
  );
};
