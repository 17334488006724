import React from "react";
import { Box, Container, Typography, Button, Grid, Link, } from "@mui/material";
export const ContactBanner = () =>{
    return (
        <Box
        sx={{
            background: "#282828",
            py: 8,
        }}
    >
       <Container>
        <Grid container spacing={2}
            sx={{
                display: "flex",
                alignItems: "center"
            }}
        >
            <Grid item xs={8}>
                <Typography
                    sx={{
                        fontFamily: "Montserrat",
                        fontWeight: 600,
                        color: "#FFFFFF",
                        fontSize: "1.8rem"

                    }}
                >
                    Ready To Optimize Your Treasury Functions ? 
                </Typography>
            </Grid>
            <Grid xs={4}>
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        width: "100%"
                    }}
                >
                    <Button
                        sx={{
                            background: "#4060B1",
                            py: 2,
                            px: 8,
                        }}
                    >
                        <Link
                            to='/contact'
                            style={{
                                textDecoration: "none"
                            }}
                        >
                            <Typography
                                sx={{
                                    fontFamily: "Poppins",
                                    fontWeight: 700,
                                    color: "#FFFFFF"
                                }}
                            >
                                Let's Talk
                            </Typography>
                        </Link>
                    </Button>
                </Box>
            </Grid>
        </Grid>
       </Container>
    </Box>
    )
}